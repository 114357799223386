<style lang="scss" scoped>
.hero-title {
  .hero-text {
    font-size: 700%;
    font-family: "MoonFlower";
    font-weight: bold;
    letter-spacing: 0.1em;
    text-align: center;
  }
}
.btn:hover,
.btn:active,
.btn:focus {
  color: unset;
  background-color: unset !important;
  text-decoration: none !important;
  box-shadow: none !important;
}
</style>

<template>
  <div class="page-container">
    <page-header
      title="Los Jurados"
      header-class="jurado"
      subtitle=""
      :bg-url="assets.headerBgUrl"
      bg-position="left bottom"
      bg-size=""
    />
    <div class="content jurado">
      <div class="content-outer">
        <jury-container
          :title="jury.title"
          :text="jury.text"
          :imgUrl="jury.bgImage"
          :titleColor="jury.titleColor"
          :imgRight="jury.imgRight === '1'"
          v-for="(jury, index) in $options.configs.jury"
          :key="index"
        ></jury-container>
        <div class="mx-md-5 px-md-5">
          <div class="content-actions d-flex px-md-5 mx-md-5">
            <b-button
              v-b-modal.login-modal
              squared
              variant="outline-success"
              class="flex-grow-shrink-0 mr-2"
              style="font-size: 100%"
              :style="`color:${$options.configs.buttonColor};border-color:${$options.configs.buttonColor}`"
            >
              <div class="d-flex flex-column">
                <div class="pt-2">
                  <strong>PARTICIPAR</strong>
                </div>
                <div style="font-size: 200%">
                  <font-awesome-icon icon="vote-yea"></font-awesome-icon>
                </div>
              </div>
            </b-button>
            <b-button
              type="button"
              v-b-modal="$options.user && $options.user.isLogged ? '' : 'login-modal'"
              :to="$options.user && $options.user.isLogged ? { name: 'drawings' } : {}"
              squared
              variant="outline-success"
              class="flex-grow-shrink-0 mr-2"
              style="font-size: 100%"
              :style="`color:${$options.configs.buttonColor};border-color:${$options.configs.buttonColor}`"
            >
              <div class="d-flex flex-column">
                <div class="pt-2">
                  <strong>VOTAR</strong>
                </div>
                <div style="font-size: 200%">
                  <font-awesome-icon icon="person-booth"></font-awesome-icon>
                </div>
              </div>
            </b-button>
            <b-button
              v-b-toggle.sidebar-menu
              squared
              variant="outline-success"
              class="flex-grow-shrink-0"
              style="font-size: 100%"
              :style="`color:${$options.configs.buttonColor};border-color:${$options.configs.buttonColor}`"
            >
              <div class="d-flex flex-column">
                <div class="pt-2">
                  <strong>EXPLORA EL MENÚ</strong>
                </div>
                <div style="font-size: 200%">
                  <font-awesome-icon icon="compass"></font-awesome-icon>
                </div>
              </div>
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <page-footer />
  </div>
</template>

<script>
import PageHeader from "../components/PageHeader";
import PageFooter from "../components/PageFooter.vue";
import JuryContainer from '../components/jury/JuryContainer.vue';

export default {
  components: { PageHeader, PageFooter, JuryContainer },
  name: "ContestBasis",
  configs: window.configs.jury,
  data() {
    return {
      assets: {
        headerBgUrl: "",
        contentBgCoch: "",
        contentBgConejo: "",
        contentBgEsc: "",
        contentBgFlower: "",
      },
    };
  },
  mounted() {
    this.getAssets();
  },
  methods: {
    getAssets() {
      this.assets = window.assets.jury;
    },
  },
};
</script>
