var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-input-group",
    { staticClass: "mb-2 flex-nowrap", attrs: { size: "lg" } },
    [
      _c(
        "b-input-group-text",
        {
          staticStyle: { "background-color": "transparent" },
          attrs: { slot: "prepend" },
          slot: "prepend"
        },
        [_vm._v("Concursos")]
      ),
      _vm._v(" "),
      _c("b-form-select", {
        attrs: { options: _vm.contests },
        model: {
          value: _vm.selectedId,
          callback: function($$v) {
            _vm.selectedId = $$v
          },
          expression: "selectedId"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }