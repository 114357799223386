<style lang="scss" scoped></style>

<template>
  <b-input-group size="lg" class="mb-2 flex-nowrap">
    <b-input-group-text slot="prepend" style="background-color:transparent"
      >Categorías</b-input-group-text
    >
    <b-form-select v-model="selectedId" :options="categories"></b-form-select>
  </b-input-group>
</template>

<script>
import axios from "axios"

export default {
  name: "CategoryFilterInput",
  props: ["contestId"],
  data() {
    return {
      categories: [],
      selectedId: null,
    }
  },
  mounted() {
    this.getCategories(this.contestId)
  },
  methods: {
    getCategories(contestId = null, onFail = () => {}) {
      let params = {
        contest_id: contestId
      }
      axios
        .get(`/api/categories`, {
          params,
        })
        .then((response) => {
          // console.log(response);
          this.categories = [
            { value: null, text: "Todas" },
            ...response.data.data.map((item) => {
              return { value: item.id, text: item.name }
            }),
          ]
        })
        .catch((error) => {
          // handle error
          console.log(error)
          onFail(error)
        })
        .then(() => {
          // always executed
        })
    },
  },
  watch: {
    selectedId: function(newVal, oldVal) {
      this.$emit("change", newVal)
    },
    contestId: function(newVal, oldVal) {
      this.getCategories(newVal)
      this.selectedId = null
    }
  },
}
</script>

<style></style>
