<style lang="scss" scoped>
.bottom-menu-spacer {
  @media only screen and (max-width: 1100px) {
    height: 5rem;
  }
}
</style>
<template>
  <div>
    <menu-sidebar/>
    <login-modal/>
    <registration-modal/>
    <router-view></router-view>
    <div class="bottom-menu-spacer"></div>
  </div>
</template>

<script>
import LoginModal from '../components/LoginModal';
import MenuSidebar from '../components/menu/MenuSidebar';
import RegistrationModal from '../components/RegistrationModal.vue';
export default {
  components: { MenuSidebar, LoginModal, RegistrationModal },
  name: "App",
};
</script>
