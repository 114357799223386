var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-input-group",
    { staticClass: "mb-2 flex-nowrap", attrs: { size: "lg" } },
    [
      _c(
        "b-input-group-text",
        {
          staticStyle: { "background-color": "transparent" },
          attrs: { slot: "prepend" },
          slot: "prepend"
        },
        [_vm._v("Ordenar")]
      ),
      _vm._v(" "),
      _c("b-form-select", {
        attrs: { options: _vm.orderByOptions },
        model: {
          value: _vm.orderBy,
          callback: function($$v) {
            _vm.orderBy = $$v
          },
          expression: "orderBy"
        }
      }),
      _vm._v(" "),
      _c(
        "b-input-group-text",
        {
          staticStyle: { "background-color": "transparent" },
          attrs: { slot: "append" },
          slot: "append"
        },
        [
          _c(
            "b-form-checkbox",
            {
              staticClass: "mr-n2",
              attrs: { switch: "" },
              model: {
                value: _vm.orderAsc,
                callback: function($$v) {
                  _vm.orderAsc = $$v
                },
                expression: "orderAsc"
              }
            },
            [
              _c("font-awesome-icon", {
                attrs: {
                  icon: _vm.orderAsc ? "sort-amount-up" : "sort-amount-down-alt"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }