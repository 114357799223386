var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticStyle: {
        margin: "auto",
        background: "rgba(241, 242, 243, 0)",
        display: "block"
      },
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        width: "200px",
        height: "200px",
        viewBox: "0 0 100 100",
        preserveAspectRatio: "xMidYMid"
      }
    },
    [
      _c("circle", {
        attrs: {
          cx: "50",
          cy: "50",
          r: "30",
          stroke: "rgba(148, 209, 55, 0)",
          "stroke-width": "10",
          fill: "none"
        }
      }),
      _vm._v(" "),
      _c(
        "circle",
        {
          attrs: {
            cx: "50",
            cy: "50",
            r: "30",
            stroke: "#5fa347",
            "stroke-width": "8",
            "stroke-linecap": "round",
            fill: "none"
          }
        },
        [
          _c("animateTransform", {
            attrs: {
              attributeName: "transform",
              type: "rotate",
              repeatCount: "indefinite",
              dur: "1.7857142857142856s",
              values: "0 50 50;180 50 50;720 50 50",
              keyTimes: "0;0.5;1"
            }
          }),
          _vm._v(" "),
          _c("animate", {
            attrs: {
              attributeName: "stroke-dasharray",
              repeatCount: "indefinite",
              dur: "1.7857142857142856s",
              values:
                "3.7699111843077517 184.72564803107983;135.71680263507906 52.77875658030851;3.7699111843077517 184.72564803107983",
              keyTimes: "0;0.5;1"
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }