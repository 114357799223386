var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "more-info-page" }, [
    _c("div", { staticClass: "inner-container" }, [
      _c("div", {
        staticClass: "title",
        style: { color: _vm.titleColor },
        domProps: { innerHTML: _vm._s(_vm.title) }
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "text",
        domProps: { innerHTML: _vm._s(_vm.text) }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }