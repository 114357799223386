<style lang="scss" scoped>
.hero-title {
  .hero-text {
    font-size: 700%;
    font-family: "MoonFlower";
    font-weight: bold;
    letter-spacing: 0.1em;
    text-align: center;
  }
}
.pe-none {
  pointer-events: none;
}
.title-outer-container {
  margin-bottom: 5em;
}
.content {
  margin: 0px 10rem 10rem;
  @media only screen and (max-width: 1200px) {
    margin: 0px 5rem 8rem;
  }
  @media only screen and (max-width: 767px) {
    margin: 0px 1rem 6rem;
  }
  .list-container {
    position: relative;
    // .list-inner-container {
    //   // .list-item {
    //   // }
    // }
  }
}
.searchbar-container {
  margin-bottom: 2rem;
  display: flex;
  @media only screen and (max-width: 1200px) {
    flex-direction: column;
  }
  .orderby-container {
    width: 40%;
    @media only screen and (max-width: 1200px) {
      width: unset;
    }
  }
  .filter-container {
    width: 35%;
    margin-right: 0.5em;
    @media only screen and (max-width: 1200px) {
      width: unset;
    }
  }
}
</style>

<template>
  <div class="page-container">
    <div class="title-outer-container">
      <page-header
        title="Mis Dibujos"
        header-class="dibujos"
        subtitle=""
        :bg-url="assets.headerBgUrl"
        :bg-position="$options.configs.header.bgPosition"
        :bg-size="$options.configs.header.bgSize"
        :title-color="$options.configs.header.titleColor"
      />
    </div>
    <div class="content dibujos">
      <div class="searchbar-container">
        <search-input
          @clear="clearSearch()"
          @change="onSearchChange"
          class="mr-2"
        ></search-input>
        <category-filter-input
          @change="onCategoryFilterChange"
          class="filter-container"
        />
        <contest-filter-input
          @change="onContestFilterChange"
          class="filter-container"
        />
        <order-by-input
          :orderByOptions="orderByOptions"
          @change="onOrderChange"
          @changeAsc="onOrderAscChange"
          class="orderby-container"
        ></order-by-input>
      </div>
      <div class="list-container">
        <loader v-if="loading"></loader>
        <div class="list-inner-container" v-if="drawings.length > 0">
          <div
            class="list-item"
            v-for="(drawing, index) in drawings"
            :key="drawing.id"
          >
            <my-drawing-item
              :title="drawing.title"
              :src="drawing.img_path"
              :description="drawing.description"
              :createdAt="drawing.createdAt"
              :status="drawing.status"
              :category="drawing.category"
              :contest="drawing.contest"
              :index="index"
            />
          </div>
        </div>
        <div class="text-center" style="font-size:150%" v-else>
          No has subido dibujos.
          <router-link
            :to="{ name: 'uploadDrawing' }"
            v-if="$options.user && $options.user.isLogged"
            >Sube tu Dibujo</router-link
          >
        </div>
      </div>
    </div>
    <page-footer />
  </div>
</template>

<script>
import PageHeader from "../components/PageHeader";
import PageFooter from "../components/PageFooter.vue";
import axios from "axios";
import Loader from "../components/loaders/Loader.vue";
import SearchInput from "../components/forms/SearchInput.vue";
import OrderByInput from "../components/forms/OrderByInput.vue";
import MyDrawingItem from "../components/drawings/MyDrawingItem.vue";
import CategoryFilterInput from "../components/forms/CategoryFilterInput.vue";
import ContestFilterInput from "../components/forms/ContestFilterInput.vue";

export default {
  components: {
    PageHeader,
    PageFooter,
    Loader,
    SearchInput,
    OrderByInput,
    MyDrawingItem,
    CategoryFilterInput,
    ContestFilterInput,
  },
  name: "MyDrawings",
  configs: window.configs.drawings,
  user: window.configs.user,
  data() {
    return {
      assets: {
        headerBgUrl: "",
      },
      drawings: [],
      loading: false,
      search: "",
      categoryFilterId: null,
      contestFilterId: null,
      orderBy: "created",
      orderAsc: false,
      orderByOptions: [{ value: "created", text: "Fecha" }],
    };
  },
  mounted() {
    this.getAssets();
  },
  methods: {
    getAssets() {
      this.assets = window.assets.drawings;
      // this.drawings = this.getDrawings();
      this.getDrawings();
    },
    getDrawings(onFail = () => {}) {
      this.loading = true;
      let query = `/api/drawings?by_me=true&page=${this.page}&order_by=${
        this.orderBy
      }&order=${this.orderAsc ? "ASC" : "DESC"}`;
      if (this.search !== "") {
        query += "&search=" + this.search;
      }
      if (this.categoryFilterId) {
        query += "&category_id=" + this.categoryFilterId;
      }
      if (this.contestFilterId) {
        query += "&contest_id=" + this.contestFilterId;
      }
      axios
        .get(query)
        .then((response) => {
          // console.log(response);
          this.drawings = response.data.data;
        })
        .catch((error) => {
          // handle error
          console.log(error);
          onFail(error);
        })
        .then(() => {
          // always executed
          this.loading = false;
        });
    },
    clearSearch() {
      this.search = "";
      this.getDrawings();
    },
    onSearchChange(val) {
      this.search = val;
      this.getDrawings();
    },
    onCategoryFilterChange(val) {
      this.categoryFilterId = val;
      this.getDrawings();
    },
    onContestFilterChange(val) {
      this.contestFilterId = val;
      this.getDrawings();
    },
    onOrderChange(val) {
      this.orderBy = val;
      this.getDrawings();
    },
    onOrderAscChange(val) {
      this.orderAsc = val;
      this.getDrawings();
    },
  },
};
</script>
