var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-input-group",
    { staticClass: "mb-2", attrs: { size: "lg" } },
    [
      _c(
        "b-input-group-text",
        {
          staticStyle: { "background-color": "transparent" },
          attrs: { slot: "prepend" },
          slot: "prepend"
        },
        [_c("b-icon", { attrs: { icon: "search" } })],
        1
      ),
      _vm._v(" "),
      _c("b-form-input", {
        staticStyle: { "border-left": "0px", "border-right": "0px" },
        attrs: {
          id: "search-input",
          placeholder: "Busca título o descripción",
          debounce: "300"
        },
        on: { keyup: _vm.onKeypress },
        model: {
          value: _vm.value,
          callback: function($$v) {
            _vm.value = $$v
          },
          expression: "value"
        }
      }),
      _c(
        "b-button",
        {
          staticStyle: { "border-left": "0px" },
          attrs: {
            slot: "append",
            variant: "secondary",
            disabled: _vm.value === ""
          },
          on: {
            click: function($event) {
              return _vm.clear()
            }
          },
          slot: "append"
        },
        [_c("b-icon", { attrs: { icon: "x" } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }