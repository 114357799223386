var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.title && _vm.title != ""
    ? _c("div", { staticClass: "content-inner" }, [
        _c(
          "div",
          {
            staticClass: "bio-image-container mx-md-5",
            class: _vm.imgRight ? "img-right" : ""
          },
          [
            _c("div", {
              staticClass: "img-bg bg-img",
              style: "background-image: url('" + _vm.imgUrl + "');"
            }),
            _vm._v(" "),
            _c("div", {
              staticClass: "title",
              style: { color: _vm.titleColor },
              domProps: { innerHTML: _vm._s(_vm.title) }
            })
          ]
        ),
        _vm._v(" "),
        _c("div", {
          staticClass: "bio-container mx-md-5",
          class: _vm.imgRight ? "img-right" : "",
          domProps: { innerHTML: _vm._s(_vm.text) }
        })
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }