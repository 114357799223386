<style lang="scss" scoped>
.hero-title {
  .hero-text {
    font-size: 700%;
    font-family: "MoonFlower";
    font-weight: bold;
    letter-spacing: 0.1em;
    text-align: center;
  }
}
.btn:hover,
.btn:active,
.btn:focus {
  color: unset;
  background-color: unset !important;
  text-decoration: none !important;
  box-shadow: none !important;
}
</style>

<template>
  <div class="page-container">
    <page-header
      :title="$options.configs.title"
      header-class="bases"
      :subtitle="$options.configs.subTitle"
      :bg-url="assets.headerBgUrl"
      bg-position="left bottom"
      bg-size="40%"
      :title-color="$options.configs.titleColor"
    />
    <div class="content bases">
      <div class="more-info-container">
        <basis-info
          :title="intro.introTitle"
          :text="intro.introContent"
          :titleColor="intro.titleColor"
          v-for="(intro, index) in $options.configs.intro"
          :key="index"
        ></basis-info>
      </div>
      <div class="hero-title">
        <div class="hero-text" :style="`color:${$options.configs.heroColor};`">
          ¿Cómo Participar?
        </div>
      </div>
      <basis-step
        :title="step.stepTitle"
        :text="step.stepContent"
        :title2="step.title2"
        :text2="step.text2"
        :bgUrl="step.bgImage"
        :bgPosition="step.bgPosition"
        :bgSize="step.bgSize"
        :titleColor="step.titleColor"
        :title2Color="step.title2Color"
        :rightAligned="step.rightAligned === '1'"
        v-for="(step, index) in $options.configs.steps"
        :key="index"
      ></basis-step>
      <div class="more-info-container">
        <basis-info
          :title="info.moreInfoTitle"
          :text="info.moreInfoContent"
          :titleColor="info.titleColor"
          v-for="(info, index) in $options.configs.moreInfo"
          :key="index"
        ></basis-info>
      </div>
      <div class="more-info-container pt-0">
        <div class="content-actions d-flex px-md-5 mx-md-5">
          <b-button
            type="button"
            :to="{ name: 'jury' }"
            squared
            variant="outline-success"
            class="flex-grow-shrink-0 mr-2"
            style="font-size: 80%"
            :style="`color:${$options.configs.buttonColor};border-color:${$options.configs.buttonColor}`"
          >
            <div class="d-flex flex-column">
              <div class="pt-2">
                <strong>LOS JURADOS</strong>
              </div>
              <div style="font-size: 200%">
                <font-awesome-icon icon="users"></font-awesome-icon>
              </div>
            </div>
          </b-button>
          <b-button
            v-b-toggle.sidebar-menu
            squared
            variant="outline-success"
            class="flex-grow-shrink-0"
            style="font-size: 80%"
            :style="`color:${$options.configs.buttonColor};border-color:${$options.configs.buttonColor}`"
          >
            <div class="d-flex flex-column">
              <div class="pt-2">
                <strong>EXPLORA EL MENÚ</strong>
              </div>
              <div style="font-size: 200%">
                <font-awesome-icon icon="compass"></font-awesome-icon>
              </div>
            </div>
          </b-button>
          <b-button
            v-b-modal="$options.user && $options.user.isLogged ? '' : 'login-modal'"
            :to="$options.user && $options.user.isLogged ? { name: 'uploadDrawing' } : {}"
            squared
            variant="outline-success"
            class="flex-grow-shrink-0 ml-2"
            style="font-size: 80%"
            :style="`color:${$options.configs.buttonColor};border-color:${$options.configs.buttonColor}`"
          >
            <div class="d-flex flex-column">
              <div class="pt-2">
                <strong>PARTICIPAR</strong>
              </div>
              <div style="font-size: 200%">
                <font-awesome-icon icon="vote-yea"></font-awesome-icon>
              </div>
            </div>
          </b-button>
        </div>
      </div>
    </div>
    <page-footer />
  </div>
</template>

<script>
import PageHeader from "../components/PageHeader";
import PageFooter from "../components/PageFooter.vue";
import BasisStep from "../components/contest_basis/BasisStep.vue";
import BasisInfo from "../components/contest_basis/BasisInfo.vue";

export default {
  components: { PageHeader, PageFooter, BasisStep, BasisInfo },
  name: "ContestBasis",
  configs: window.configs.basis,
  user: window.configs.user,
  data() {
    return {
      assets: {
        headerBgUrl: "",
        contentBgCoch: "",
        contentBgConejo: "",
        contentBgEsc: "",
        contentBgFlower: "",
      },
    };
  },
  mounted() {
    this.getAssets();
    // console.log(this.$options.configs)
  },
  methods: {
    getAssets() {
      this.assets = window.assets.basis;
    },
  },
};
</script>
