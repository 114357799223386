<style lang="scss" scoped></style>

<template>
  <b-input-group size="lg" class="mb-2 flex-nowrap">
    <b-input-group-text slot="prepend" style="background-color:transparent"
      >Ordenar</b-input-group-text
    >
    <b-form-select v-model="orderBy" :options="orderByOptions"></b-form-select>
    <b-input-group-text slot="append" style="background-color:transparent"
      ><b-form-checkbox switch class="mr-n2" v-model="orderAsc"
        ><font-awesome-icon
          :icon="orderAsc ? 'sort-amount-up' : 'sort-amount-down-alt'"
        ></font-awesome-icon></b-form-checkbox
    ></b-input-group-text>
  </b-input-group>
</template>

<script>
export default {
  name: "OrderByInput",
  props: ['orderByOptions'],
  data() {
    return {
      orderBy: this.orderByOptions[0].value,
      orderAsc: false
    };
  },
  methods: {
  },
  watch: {
    orderBy: function(newVal, oldVal) {
      this.$emit("change", newVal);
    },
    orderAsc: function(newVal, oldVal) {
      this.$emit("changeAsc", newVal);
    },
  },
};
</script>

<style></style>
