var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c(
        "div",
        { staticClass: "title-outer-container" },
        [
          _c("page-header", {
            attrs: {
              title: "Mis Dibujos",
              "header-class": "dibujos",
              subtitle: "",
              "bg-url": _vm.assets.headerBgUrl,
              "bg-position": _vm.$options.configs.header.bgPosition,
              "bg-size": _vm.$options.configs.header.bgSize,
              "title-color": _vm.$options.configs.header.titleColor
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "content dibujos" }, [
        _c(
          "div",
          { staticClass: "searchbar-container" },
          [
            _c("search-input", {
              staticClass: "mr-2",
              on: {
                clear: function($event) {
                  return _vm.clearSearch()
                },
                change: _vm.onSearchChange
              }
            }),
            _vm._v(" "),
            _c("category-filter-input", {
              staticClass: "filter-container",
              on: { change: _vm.onCategoryFilterChange }
            }),
            _vm._v(" "),
            _c("contest-filter-input", {
              staticClass: "filter-container",
              on: { change: _vm.onContestFilterChange }
            }),
            _vm._v(" "),
            _c("order-by-input", {
              staticClass: "orderby-container",
              attrs: { orderByOptions: _vm.orderByOptions },
              on: { change: _vm.onOrderChange, changeAsc: _vm.onOrderAscChange }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "list-container" },
          [
            _vm.loading ? _c("loader") : _vm._e(),
            _vm._v(" "),
            _vm.drawings.length > 0
              ? _c(
                  "div",
                  { staticClass: "list-inner-container" },
                  _vm._l(_vm.drawings, function(drawing, index) {
                    return _c(
                      "div",
                      { key: drawing.id, staticClass: "list-item" },
                      [
                        _c("my-drawing-item", {
                          attrs: {
                            title: drawing.title,
                            src: drawing.img_path,
                            description: drawing.description,
                            createdAt: drawing.createdAt,
                            status: drawing.status,
                            category: drawing.category,
                            contest: drawing.contest,
                            index: index
                          }
                        })
                      ],
                      1
                    )
                  }),
                  0
                )
              : _c(
                  "div",
                  {
                    staticClass: "text-center",
                    staticStyle: { "font-size": "150%" }
                  },
                  [
                    _vm._v("\n        No has subido dibujos.\n        "),
                    _vm.$options.user && _vm.$options.user.isLogged
                      ? _c(
                          "router-link",
                          { attrs: { to: { name: "uploadDrawing" } } },
                          [_vm._v("Sube tu Dibujo")]
                        )
                      : _vm._e()
                  ],
                  1
                )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("page-footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }