var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", {}, [
    _c("div", { staticClass: "footer-container" }, [
      _c("div", { staticClass: "copyright" }, [
        _vm._v("© " + _vm._s(_vm.currentYear) + " Nuestra Naturaleza")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "credits" }, [
        _c("div", {
          staticClass: "bg-img signature-img mr-3",
          style: "background-image:url('" + _vm.assets.footerImgUrl + "');"
        }),
        _vm._v(" "),
        _c("div", {
          domProps: { innerHTML: _vm._s(_vm.$options.configs.footerCredits) }
        })
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }