var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "step-container bg-img",
      class: _vm.rightAligned ? "right-aligned" : "",
      style:
        "background-image: url('" +
        _vm.bgUrl +
        "');background-size:" +
        _vm.bgSize +
        ";background-position:" +
        _vm.bgPosition +
        ";"
    },
    [
      _c("div", { staticClass: "inner-container" }, [
        _c("div", {
          staticClass: "title",
          style: { color: _vm.titleColor },
          domProps: { innerHTML: _vm._s(_vm.title) }
        }),
        _vm._v(" "),
        _c("div", {
          staticClass: "text",
          domProps: { innerHTML: _vm._s(_vm.text) }
        }),
        _vm._v(" "),
        _vm.title2
          ? _c("div", {
              staticClass: "title",
              style: { color: _vm.title2Color },
              domProps: { innerHTML: _vm._s(_vm.title2) }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.text2
          ? _c("div", {
              staticClass: "text",
              domProps: { innerHTML: _vm._s(_vm.text2) }
            })
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }