<style lang="scss" scoped>
.hover-underline {
  &:hover {
    text-decoration: underline;
  }
}
.cursor-pointer {
  cursor: pointer;
}
</style>

<template>
  <b-modal
    id="login-modal"
    title="Ingresar"
    header-bg-variant="info"
    header-text-variant="light"
    header-class="align-items-baseline position-relative"
    hide-footer
    centered
    no-stacking
  >
    <template #modal-header="{ close }">
      <b-icon icon="key" class="mr-2" aria-hidden="true" />
      <h5>Ingresar</h5>
      <b-button-close @click="close()" />
    </template>

    <template #default>
      <loader v-show="loading"></loader>
      <p class="mb-2">¡Ingresa para participar o votar!</p>
      <b-form action="/login" @submit.stop.prevent="onSubmit">
        <b-form-group label-size="lg" label="Correo" label-for="login-email">
          <b-input-group size="lg">
            <b-input-group-prepend>
              <b-input-group-text>
                <b-icon icon="person" />
              </b-input-group-text>
            </b-input-group-prepend>
            <b-form-input
              id="login-email"
              v-model="form.username"
              :state="$v.form.username.$invalid ? false : null"
              autocomplete="username"
            ></b-form-input>
          </b-input-group>
          <div class="text-muted text-right" v-if="$v.form.username.$invalid">
            Debe ser un correo válido.
          </div>
        </b-form-group>
        <b-form-group
          label-size="lg"
          label="Contraseña"
          label-for="login-password"
        >
          <b-input-group size="lg">
            <b-input-group-prepend>
              <b-input-group-text>
                <b-icon icon="key" />
              </b-input-group-text>
            </b-input-group-prepend>
            <b-form-input
              id="login-password"
              type="password"
              v-model="form.password"
              :state="$v.form.password.$invalid ? false : null"
              autocomplete="current-password"
            ></b-form-input>
          </b-input-group>
          <div class="text-muted text-right" v-if="$v.form.password.$invalid">
            Requerida
          </div>
        </b-form-group>
        <div class="text-success text-center py-2" v-if="emailVerificationSent">
          <div>Hemos enviado un correo a {{emailVerification}}</div>
        </div>
        <div class="text-danger text-center py-2" v-if="submitError">
          <div v-if="errorMsg === '_disabled'">
            Tu usuario ha sido deshabilitado.
          </div>
          <div v-else-if="errorMsg === '_email_verification'">
            No has verificado tu correo.<br>
            <span class="text-muted hover-underline cursor-pointer" style="font-size:80%" @click="resendEmailVerification()">Para volver a mandar el correo de verificación has click aqui</span>
          </div>
          <div v-else-if="errorMsg === 'Too many failed login attempts, please try again in 1 minute.'">
            Demasiados intentos consecutivos, intenta nuévamente en 1 minuto.
          </div>
          <div v-else-if="errorCode === 401">
            Credenciales incorrectas.<br>
            <span class="text-muted hover-underline cursor-pointer" style="font-size:80%" @click="resetPassword()">Olvidé mi contraseña</span>
          </div>
          <div v-else>
            Hubo un error con tu solicitud.
          </div>
        </div>
        <div class="mt-4 d-flex">
          <b-button type="button" variant="info" class="mr-2" :disabled="loading" v-b-modal.registration-modal 
            ><b-icon
              class="mr-2"
              icon="person-plus"
              aria-hidden="true"
            />Registrarme</b-button
          >
          <b-button type="submit" variant="success" :disabled="$v.form.$invalid || loading"
            ><b-icon
              class="mr-2"
              icon="shield-check"
              aria-hidden="true"
            />Ingresar</b-button
          >
        </div>
      </b-form>
    </template>

    <template #modal-footer="{close}">
      <div class="w-100">
        <b-button
          variant="primary"
          size="sm"
          class="float-right"
          @click="close()"
        >
          Close
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import axios from 'axios';
import Loader from './loaders/Loader';

export default {
  name: "LoginModal",
  user: window.configs.user,
  components: {Loader},
  data() {
    return {
      form: {
        username: new String(this.$options.user.lastUsername),
        csrfToken: new String(this.$options.user.loginCsrf),
        password: "",
      },
      loading: false,
      submitError: false,
      errorMsg: "",
      errorCode: null,
      emailVerificationSent: false,
      emailVerification: ""
    };
  },
  validations: {
    form: {
      username: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },
  methods: {
    onSubmit(e) {
      this.emailVerificationSent = false;
      this.submitError = false;
      this.loading = true;
      // console.log(e);
      let fd = new FormData();
      fd.append('username', this.form.username);
      fd.append('password', this.form.password);
      fd.append('_csrf_token', this.form.csrfToken);
      axios.post('/login', fd)
        .then(response => {
          // console.log(response);
          this.submitError = false;
          if (response.data.redirect) {
            window.location.replace(response.data.redirect);
          }
          else {
            window.location.reload();
          }
        })
        .catch(error => {
          console.log(error);
          // console.log("error message",error.message);
          // console.log("error code",error.code);
          // console.log("error response",error.response);
          // console.log("error JSON",JSON.stringify(error, null, 2));
          console.log("error response",error.response.data.message);
          this.errorCode = error.response.status;
          this.errorMsg = error.response.data.message;
          this.submitError = true;
          this.loading = false;
        });
    },
    resendEmailVerification() {
      this.loading = true;
      let fd = new FormData();
      fd.append('username', this.form.username);
      axios.post('/registration/resend-token', fd)
        .then(response => {
          // console.log(response);
          this.loading = false;
          this.submitError = false;
          this.emailVerificationSent = true;
          this.emailVerification = this.form.username;
        })
        .catch(error => {
          console.log(error);
          this.submitError = true;
          this.loading = false;
        });
    },
    resetPassword() {
      this.loading = true;
      let fd = new FormData();
      fd.append('username', this.form.username);
      axios.post('/reset-password', fd)
        .then(response => {
          // console.log(response);
          this.loading = false;
          this.submitError = false;
          this.emailVerificationSent = true;
          this.emailVerification = this.form.username;
        })
        .catch(error => {
          console.log(error);
          this.submitError = true;
          this.loading = false;
        });
    }
  },
};
</script>
