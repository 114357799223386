var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c(
        "div",
        { staticClass: "title-outer-container" },
        [
          _c("page-header", {
            attrs: {
              title: _vm.$options.configs.header.title,
              "header-class": "dibujos",
              subtitle: "",
              "bg-url": _vm.assets.headerBgUrl,
              "bg-position": _vm.$options.configs.header.bgPosition,
              "bg-size": _vm.$options.configs.header.bgSize,
              "title-color": _vm.$options.configs.header.titleColor
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "content dibujos" }, [
        _c(
          "div",
          { staticClass: "searchbar-container" },
          [
            _c("search-input", {
              staticClass: "mr-2",
              on: {
                clear: function($event) {
                  return _vm.clearSearch()
                },
                change: _vm.onSearchChange
              }
            }),
            _vm._v(" "),
            _c("category-filter-input", {
              staticClass: "filter-container",
              attrs: { contestId: _vm.contestFilterId },
              on: { change: _vm.onCategoryFilterChange }
            }),
            _vm._v(" "),
            _c("contest-filter-input", {
              staticClass: "filter-container",
              on: { change: _vm.onContestFilterChange }
            }),
            _vm._v(" "),
            _c("order-by-input", {
              staticClass: "orderby-container",
              attrs: { orderByOptions: _vm.orderByOptions },
              on: { change: _vm.onOrderChange, changeAsc: _vm.onOrderAscChange }
            })
          ],
          1
        ),
        _vm._v(" "),
        _vm.drawings.length > 0 || _vm.page > 1
          ? _c(
              "div",
              { staticClass: "drawings-carrousel" },
              [
                _vm.loading ? _c("loader") : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "carrousel-page" },
                  _vm._l(_vm.drawings, function(drawing, index) {
                    return _c(
                      "div",
                      { key: drawing.id, staticClass: "page-item" },
                      [
                        _c("drawing-list-item", {
                          attrs: {
                            title: drawing.title,
                            src: drawing.img_path,
                            publicVote: drawing.public_vote,
                            createdByMe: drawing.created_by_me,
                            index: index
                          },
                          on: {
                            click: function($event) {
                              return _vm.onClickDrawing(drawing, index)
                            }
                          }
                        })
                      ],
                      1
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _c("div", { staticClass: "carrousel-controls" }, [
                  _c(
                    "div",
                    {
                      staticClass: "carrousel-control right",
                      class:
                        _vm.page < _vm.totalPages && !_vm.loading
                          ? ""
                          : "pe-none disabled",
                      on: {
                        click: function($event) {
                          return _vm.nextPage()
                        }
                      }
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: "angle-right" }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "carrousel-control left",
                      class:
                        _vm.page > 1 && !_vm.loading ? "" : "pe-none disabled",
                      on: {
                        click: function($event) {
                          return _vm.previousPage()
                        }
                      }
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: "angle-right" }
                      })
                    ],
                    1
                  )
                ])
              ],
              1
            )
          : _vm.search !== ""
          ? _c(
              "div",
              {
                staticClass: "text-center",
                staticStyle: { "font-size": "150%" }
              },
              [_vm._v("\n      No hay dibujos con tu búsqueda.\n    ")]
            )
          : _c(
              "div",
              {
                staticClass: "text-center",
                staticStyle: { "font-size": "150%" }
              },
              [
                _vm._v("\n      Aún no hay dibujos.\n      "),
                _vm.$options.user && _vm.$options.user.isLogged
                  ? _c(
                      "router-link",
                      { attrs: { to: { name: "uploadDrawing" } } },
                      [_vm._v("Sube tu Dibujo")]
                    )
                  : _vm._e()
              ],
              1
            )
      ]),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("image-modal", {
            attrs: { id: "drawings-modal", drawing: _vm.modalDrawing },
            on: {
              publicVoteChange: _vm.onPublicVoteChange,
              juryVoteChange: _vm.onJuryVoteChange
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("page-footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }