var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-input-group",
    [
      _c(
        "b-button",
        {
          attrs: {
            slot: "prepend",
            variant: "outline-secondary",
            disabled: _vm.value === null || _vm.value === undefined
          },
          on: {
            click: function($event) {
              _vm.value = null
            }
          },
          slot: "prepend"
        },
        [_vm._v("Cancelar")]
      ),
      _vm._v(" "),
      _vm.showDisqualified
        ? _c(
            "b-button",
            {
              attrs: {
                slot: "prepend",
                variant: _vm.value === 0 ? "danger" : "outline-danger"
              },
              on: {
                click: function($event) {
                  _vm.value = 0
                }
              },
              slot: "prepend"
            },
            [_vm._v(_vm._s(_vm.value === 0 ? "Descalificado" : "Descalificar"))]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("b-form-rating", {
        attrs: { color: "#ff8800", "no-border": "", size: _vm.size },
        model: {
          value: _vm.value,
          callback: function($$v) {
            _vm.value = $$v
          },
          expression: "value"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }