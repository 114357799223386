<style lang="scss" scoped>
$icon-size: 5em;

.menu-sidebar {
  a {
    color: inherit;
    &.active {
      text-decoration: underline;
    }
  }
}

.menu-container {
  position: fixed;
  top: 2rem;
  right: 2rem;
  display: flex;
  flex-direction: column;
  z-index: 2;
  @media only screen and (max-width: 1100px) {
    top: auto;
    bottom: 0px;
    right: 0px;
    left: 0px;
    flex-direction: row;
    height: 5rem;
    padding-bottom: 0.2rem;
    padding-top: 0.2rem;
    justify-content: space-evenly;
    background-color: #ffffff91;
  }
  .menu-toggle {
    margin-bottom: 1.5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    @media only screen and (max-width: 1100px) {
      margin-bottom: unset;
    }
    .icon {
      width: $icon-size;
      height: $icon-size;
      filter: drop-shadow(0px 2px 2px #222222);
      @media only screen and (max-width: 1100px) {
        height: 100%;
      }
    }
  }
}
.avatar-container {
  .avatar {
    height: 100%;
    width: 100%;
  }
}
.sidebar-avatar-container {
  display: flex;
  justify-content: center;
  .avatar-ring {
    position: relative;
    padding: 2em;
    border-radius: 50%;
    background-color: #80808030;
    cursor: pointer;
    transition: background-color 0.3s ease;
    &:hover {
      background-color: #80808046;
      .switch-icon-container {
        opacity: 1;
      }
    }
    .avatar-container {
      height: 10rem;
      width: 10rem;
    }
    .switch-icon-container {
      position: absolute;
      transition: opacity 0.3s ease;
      opacity: 0;
      bottom: 0;
      right: 0;
      font-size: 160%;
      line-height: 100%;
      @media only screen and (max-width: 1100px) {
        opacity: 1;
      }
    }
  }
}
.text-container {
  text-align: center;
  width: $icon-size;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  overflow-y: hidden;
  @media only screen and (max-width: 1100px) {
    padding-bottom: 0.2em;
  }
}
.grayscale {
  filter: grayscale(1);
}
.menu-user-sidebar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .user-data {
    text-align: center;
    font-size: 120%;
    overflow: hidden;
    text-overflow: ellipsis;
    &.name-container {
      padding-top: 1em;
      font-weight: bold;
    }
    // &.email-container {
    // }
  }
  .actions-container {
    padding-top: 2em;
    .action-container {
      display: flex;
      justify-content: center;
      a {
        font-size: 150%;
        color: #5e4996;
        &.active {
          text-decoration: underline;
        }
      }
    }
  }
}

body {
  &.modal-open {
    @media screen and (min-width: 1200px) {
      .menu-container {
        padding-right: 17px;
      }
    }
  }
}
</style>

<template>
  <div>
    <div class="menu-container position-fixed d-flex">
      <!-- MENU TOGGLE -->
      <div v-b-toggle.sidebar-menu class="menu-toggle hover-scale-1">
        <div
          class="icon bg-img"
          :style="`background-image:url('${assets.icon}')`"
        ></div>
        <div class="text-container">Menú</div>
      </div>
      <!-- USER TOGGLE -->
      <div
        class="menu-toggle hover-scale-1"
        v-b-toggle="$options.user.isLogged ? 'sidebar-user-menu' : ''"
        v-b-modal="$options.user.isLogged ? '' : 'login-modal'"
      >
        <div class="avatar-container icon">
          <div
            class="avatar"
            :class="$options.user.isLogged ? '' : 'grayscale'"
          >
            <div
              class="bg-img w-100 h-100"
              :style="
                `background-image:url('icon_collection/animals/${avatarFile}')`
              "
            ></div>
          </div>
        </div>
        <div class="text-container">
          {{ $options.user.isLogged ? $options.user.name : "Ingresar" }}
        </div>
      </div>
      <!-- ADMIN TOGGLE -->
      <div
        v-if="
          $options.user.roles &&
            ($options.user.roles.includes('ROLE_ADMIN') ||
              $options.user.roles.includes('ROLE_MANAGER'))
        "
        class="menu-toggle hover-scale-1"
        @click="goToAdmin()"
      >
        <div
          class="icon bg-img"
          :style="`background-image:url('${assets.adminIcon}')`"
        ></div>
        <div class="text-container">Gestor</div>
      </div>
    </div>

    <!-- MENU SIDEBAR -->
    <b-sidebar
      title="Menú"
      id="sidebar-menu"
      backdrop-variant="dark"
      bg-variant="light"
      backdrop
      shadow
      right
      width="50rem"
    >
      <div class="p-3 menu-sidebar ">
        <nav class="mb-3">
          <b-nav vertical>
            <b-nav-item
              :to="{ name: 'home' }"
              exact-active-class="active"
              :style="`color:${$options.configs.menuColor}`"
              >Inicio</b-nav-item
            >
            <b-nav-item
              :to="{ name: 'bases' }"
              exact-active-class="active"
              :style="`color:${$options.configs.menuColor}`"
              >Bases del Concurso</b-nav-item
            >
            <b-nav-item
              :to="{ name: 'jury' }"
              exact-active-class="active"
              :style="`color:${$options.configs.menuColor}`"
              >Los Jurados</b-nav-item
            >
            <b-nav-item
              :to="{ name: 'drawings' }"
              exact-active-class="active"
              :style="`color:${$options.configs.menuColor}`"
              >Dibujos</b-nav-item
            >
            <b-nav-item
              :to="{ name: 'my_drawings' }"
              v-if="$options.user.isLogged"
              exact-active-class="active"
              :style="`color:${$options.configs.menuColor}`"
              >Mis Dibujos</b-nav-item
            >
            <b-nav-item
              :to="{ name: 'uploadDrawing' }"
              v-if="$options.user.isLogged && $options.user.contestAvailable"
              exact-active-class="active"
              :style="`color:${$options.configs.menuColor}`"
              >Sube tu Dibujo</b-nav-item
            >
            <b-nav-item
              v-b-modal.login-modal
              v-if="!$options.user.isLogged"
              exact-active-class="active"
              :style="`color:${$options.configs.menuColor}`"
              >Ingresar</b-nav-item
            >
          </b-nav>
        </nav>
      </div>
    </b-sidebar>

    <!-- USER SIDEBAR -->
    <b-sidebar
      title="Mi Cuenta"
      id="sidebar-user-menu"
      backdrop-variant="dark"
      bg-variant="light"
      backdrop
      shadow
      right
      width="30rem"
      v-if="$options.user.isLogged"
      ref="sidebar"
    >
      <div class="p-3 menu-user-sidebar">
        <div class="sidebar-avatar-container">
          <div class="avatar-ring" v-b-modal="'avatar-modal'">
            <div class="switch-icon-container">
              <font-awesome-icon icon="sync-alt"></font-awesome-icon>
            </div>
            <div class="avatar-container sidebar">
              <div class="avatar">
                <div
                  class="bg-img w-100 h-100"
                  :style="
                    `background-image:url('icon_collection/animals/${avatarFile}')`
                  "
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div class="user-data name-container">
          {{ $options.user.name }} {{ $options.user.lastname }}
        </div>
        <div class="user-data email-container">{{ $options.user.email }}</div>
        <div class="actions-container">
          <div class="action-container">
            <router-link
              :to="{ name: 'my_drawings' }"
              exact-active-class="active"
              >Mis Dibujos</router-link
            >
          </div>
          <div class="action-container">
            <router-link
              :to="{ name: 'uploadDrawing' }"
              v-if="$options.user.contestAvailable"
              exact-active-class="active"
              >Sube tu Dibujo</router-link
            >
          </div>
          <div class="action-container">
            <a href="/logout" style="">Cerrar Sesión</a>
          </div>
        </div>
      </div>
      <avatar-modal />
    </b-sidebar>
  </div>
</template>

<script>
import UserSidebar from "./UserSidebar.vue";
import animalIcons from "../../resources/animal_icon_list";
import avatarModal from "../menu/AvatarModal.vue";

export default {
  name: "MenuSidebar",
  components: {
    UserSidebar,
    avatarModal,
  },
  user: window.configs.user,
  configs: window.configs.menu,
  icons: {
    animals: animalIcons,
  },
  data() {
    return {
      assets: {
        icon: "",
        adminIcon: "",
      },
      avatarFile: "",
      user: {
        name: "Edo",
        isLogged: false,
        avatar: "",
      },
    };
  },
  mounted() {
    this.getAssets();
    this.avatarFile = this.getAvatar();
  },
  methods: {
    getAssets() {
      this.assets = window.assets.menu;
    },
    getAvatar() {
      if (this.$options.user.isLogged) {
        return this.$options.user.avatar_filename;
      }
      // console.log(this.$options.icons.animals);
      let randomIndex = Math.floor(
        Math.random() * this.$options.icons.animals.length
      );
      return this.$options.icons.animals[randomIndex];
    },
    toggleUserSidebar() {
      this.$refs.sidebar.toggle();
    },
    goToAdmin() {
      // this.$router.push({path:"/admin"});
      window.location.assign("/admin");
    },
  },
};
</script>
