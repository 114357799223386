// any CSS you import will output into a single css file (app.css in this case)
import "../styles/app/app.scss";

import Vue from "vue";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
// import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import Routes from "./app_routes";
import App from "./views/App";
import "./resources/app_fa_icons.js"

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import axios from 'axios'
import VueAxios from 'vue-axios'

import Vuelidate from 'vuelidate'

Vue.use(Vuelidate)

// Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

Vue.use(VueAxios, axios);

new Vue({
  el: "#app",
  router: Routes,
  render: (h) => h(App),
});
