var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: _vm.id,
        size: "xl",
        centered: "",
        "header-class": "custom-modal-header postion-relative py-0",
        "ok-title": "Cerrar",
        "body-class": "p-0",
        "footer-class": "pt-0",
        "footer-border-variant": "white",
        "content-class": "overflow-hidden"
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function(ref) {
            var close = ref.close
            return [
              _c(
                "div",
                {
                  staticClass: "position-absolute",
                  staticStyle: { right: "0.5em", top: "0.5em", "z-index": "1" }
                },
                [
                  _c("b-button-close", {
                    on: {
                      click: function($event) {
                        return close()
                      }
                    }
                  })
                ],
                1
              )
            ]
          }
        },
        {
          key: "modal-footer",
          fn: function(ref) {
            var close = ref.close
            return [
              _c(
                "b-button",
                {
                  attrs: { size: "sm", variant: "success" },
                  on: { click: close }
                },
                [_vm._v("Cerrar")]
              )
            ]
          }
        }
      ])
    },
    [
      _vm._v(" "),
      _vm.drawing.title
        ? _c(
            "div",
            { staticClass: "content-inner" },
            [
              !_vm.loaded
                ? _c(
                    "div",
                    { staticClass: "spinner-container" },
                    [_c("image-spinner-icon", { staticClass: "spinner" })],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("b-img", {
                staticClass: "img",
                attrs: { fluid: "", src: _vm.drawing.img_path }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "drawing-description" }, [
                _c("h2", [_vm._v(_vm._s(_vm.drawing.title))]),
                _vm._v(" "),
                _c("p", [_vm._v(_vm._s(_vm.drawing.description))])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "drawing-info" }, [
                _c("div", { staticClass: "info-group" }, [
                  _c("span", { staticClass: "title" }, [_vm._v("Concurso: ")]),
                  _c("span", { staticClass: "data" }, [
                    _vm._v(_vm._s(_vm.drawing.contest.name))
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "info-group" }, [
                  _c("span", { staticClass: "title" }, [_vm._v("Categoría: ")]),
                  _c("span", { staticClass: "data" }, [
                    _vm._v(
                      _vm._s(_vm.drawing.category.name) +
                        " (" +
                        _vm._s(_vm.drawing.category.min_age) +
                        "-" +
                        _vm._s(_vm.drawing.category.max_age) +
                        "años)"
                    )
                  ])
                ])
              ]),
              _vm._v(" "),
              this.$options.user &&
              this.$options.user.isLogged &&
              this.$options.user.canPublicVote &&
              !this.$options.user.canJuryVote &&
              _vm.drawing.contest.publicVoteAllowed &&
              (!_vm.drawing.created_by_me ||
                _vm.$options.user.roles.includes("ROLE_ADMIN"))
                ? _c(
                    "div",
                    { staticClass: "rating-container" },
                    [
                      _vm.loadingVote ? _c("loader") : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "text-center mt-3" }, [
                        _c("strong", [_vm._v("Votar")])
                      ]),
                      _vm._v(" "),
                      _c("rating-input", {
                        attrs: {
                          size: "lg",
                          initValue: _vm.drawing.public_vote
                        },
                        on: { change: _vm.onPublicVoteChange }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              this.$options.user &&
              this.$options.user.isLogged &&
              this.$options.user.canPublicVote &&
              !this.$options.user.canJuryVote &&
              !_vm.drawing.contest.publicVoteAllowed &&
              (!_vm.drawing.created_by_me ||
                _vm.$options.user.roles.includes("ROLE_ADMIN"))
                ? _c("div", { staticStyle: { "text-align": "center" } }, [
                    _c("i", [
                      _vm._v(
                        "La votación pública para el concurso de este dibujo ha sido cerrada."
                      )
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              this.$options.user &&
              this.$options.user.isLogged &&
              this.$options.user.canJuryVote &&
              _vm.drawing.contest.juryVoteAllowed &&
              (!_vm.drawing.created_by_me ||
                _vm.$options.user.roles.includes("ROLE_ADMIN"))
                ? _c(
                    "div",
                    { staticClass: "rating-container" },
                    [
                      _vm.loadingVote ? _c("loader") : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "jury-vote-title" }, [
                        _c("strong", [_vm._v("Voto de Jurado")])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "jury-vote-container" },
                        _vm._l(_vm.drawing.contest.juryCriteria, function(
                          criteria
                        ) {
                          return _c(
                            "div",
                            { key: criteria.id, staticClass: "jury-vote" },
                            [
                              _c("div", { staticClass: "criteria-name" }, [
                                _c("strong", [_vm._v(_vm._s(criteria.name))])
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "criteria-description" },
                                [
                                  _c("i", [
                                    _vm._v(_vm._s(criteria.description))
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "value-table" },
                                [
                                  _c("b-table", {
                                    attrs: {
                                      items: criteria.values,
                                      small: "",
                                      fields: [
                                        {
                                          key: "value",
                                          label: "Número de Estrellas"
                                        },
                                        {
                                          key: "description",
                                          label: "Criterios de Evaluación"
                                        }
                                      ]
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("rating-input", {
                                attrs: {
                                  size: "lg",
                                  showDisqualified: true,
                                  initValue: _vm.findJuryVoteInitValue(
                                    criteria.id
                                  )
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.onJuryVoteChange(
                                      $event,
                                      criteria.id
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          )
                        }),
                        0
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              this.$options.user &&
              this.$options.user.isLogged &&
              this.$options.user.canJuryVote &&
              !_vm.drawing.contest.juryVoteAllowed &&
              (!_vm.drawing.created_by_me ||
                _vm.$options.user.roles.includes("ROLE_ADMIN"))
                ? _c("div", { staticStyle: { "text-align": "center" } }, [
                    _c("i", [
                      _vm._v(
                        "La votación de jurado para el concurso de este dibujo ha sido cerrada."
                      )
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              this.$options.user &&
              this.$options.user.isLogged &&
              _vm.drawing.created_by_me
                ? _c("div", { staticStyle: { "text-align": "center" } }, [
                    _c("i", [
                      _vm._v("No puedes votar por un dibujo que subiste.")
                    ])
                  ])
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }