var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: _vm.id,
        size: "sm",
        centered: "",
        "header-class": "custom-modal-header",
        "ok-title": "Cerrar"
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function(ref) {
            var close = ref.close
            return [
              _c("h1", [_vm._v(_vm._s(_vm.headerText))]),
              _vm._v(" "),
              _c("b-button-close", {
                on: {
                  click: function($event) {
                    return close()
                  }
                }
              })
            ]
          }
        },
        {
          key: "modal-footer",
          fn: function(ref) {
            return [
              _c(
                "b-button",
                {
                  attrs: { size: "sm", variant: "success" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("click")
                    }
                  }
                },
                [_vm._v("Cerrar")]
              )
            ]
          }
        }
      ])
    },
    [
      _vm._v(" "),
      _c("div", { staticClass: "custom-modal-content" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-center align-items-center pb-5",
            staticStyle: { "font-size": "5rem", color: "rgb(245 26 26)" }
          },
          [
            _c(
              "div",
              [_c("font-awesome-icon", { attrs: { icon: "times-circle" } })],
              1
            )
          ]
        ),
        _vm._v(" "),
        _c("p", { staticClass: "text-center" }, [_vm._v(_vm._s(_vm.content))])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }