<style lang="scss" scoped></style>

<template>
  <b-input-group size="lg" class="mb-2">
    <b-input-group-text slot="prepend" style="background-color:transparent"
      ><b-icon icon="search"></b-icon
    ></b-input-group-text>
    <b-form-input
      id="search-input"
      placeholder="Busca título o descripción"
      debounce="300"
      v-model="value"
      style="border-left: 0px;border-right:0px"
      @keyup="onKeypress"
    ></b-form-input
    ><b-button
      slot="append"
      variant="secondary"
      @click="clear()"
      :disabled="value === ''"
      style="border-left:0px"
      ><b-icon icon="x"></b-icon
    ></b-button>
  </b-input-group>
</template>

<script>
export default {
  name: "SearchInput",
  data() {
    return {
      value: "",
    };
  },
  methods: {
    clear() {
      if (this.value !== "") {
        this.value = "";
        this.$emit("clear");
      }
    },
    onKeypress(e) {
      if (e.key === "Escape") {
        this.clear();
      }
    },
  },
  watch: {
    value: function(newVal, oldVal) {
      this.$emit("change", newVal);
    },
  },
};
</script>

<style></style>
