var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-container" },
    [
      _vm.loading ? _c("loader") : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "image-preview-container pr-5" }, [
        !_vm.previewImgURL
          ? _c(
              "div",
              { staticClass: "no-image" },
              [_c("font-awesome-icon", { attrs: { icon: "file-image" } })],
              1
            )
          : _c("div", {
              staticClass: "image-preview bg-img",
              style: "background-image: url('" + _vm.previewImgURL + "');"
            })
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-container-inner" },
        [
          _c("div", { staticClass: "tabs-header-container mb-5" }, [
            _c(
              "div",
              {
                staticClass: "tabs-header-element",
                class:
                  _vm.formTabIndex === 0
                    ? "current"
                    : _vm.formTabIndex > 0
                    ? "valid"
                    : ""
              },
              [
                _vm.formTabIndex > 0
                  ? _c(
                      "div",
                      { staticClass: "check-icon" },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: "check-circle" }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "icon" },
                  [_c("font-awesome-icon", { attrs: { icon: "image" } })],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "title" }, [_vm._v("Dibujo")])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "tabs-header-separator",
                class: _vm.formTabIndex > 0 ? "valid" : ""
              },
              [_c("font-awesome-icon", { attrs: { icon: "arrow-right" } })],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "tabs-header-element",
                class:
                  _vm.formTabIndex === 1
                    ? "current"
                    : _vm.formTabIndex > 1
                    ? "valid"
                    : ""
              },
              [
                _vm.formTabIndex > 1
                  ? _c(
                      "div",
                      { staticClass: "check-icon" },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: "check-circle" }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "icon" },
                  [_c("font-awesome-icon", { attrs: { icon: "child" } })],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "title" }, [_vm._v("Participante")])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "tabs-header-separator",
                class: _vm.formTabIndex > 1 ? "valid" : ""
              },
              [_c("font-awesome-icon", { attrs: { icon: "arrow-right" } })],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "tabs-header-element",
                class:
                  _vm.formTabIndex === 2
                    ? "current"
                    : _vm.formTabIndex > 2
                    ? "valid"
                    : ""
              },
              [
                _vm.formTabIndex > 2
                  ? _c(
                      "div",
                      { staticClass: "check-icon" },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: "check-circle" }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "icon" },
                  [_c("font-awesome-icon", { attrs: { icon: "id-card" } })],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "title" }, [_vm._v("Tutor")])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "tabs-header-separator",
                class: _vm.formTabIndex > 2 ? "valid" : ""
              },
              [_c("font-awesome-icon", { attrs: { icon: "arrow-right" } })],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "tabs-header-element",
                class:
                  _vm.formTabIndex === 3
                    ? "current"
                    : _vm.formTabIndex > 3
                    ? "valid"
                    : ""
              },
              [
                _vm.formTabIndex > 3
                  ? _c(
                      "div",
                      { staticClass: "check-icon" },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: "check-circle" }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "icon" },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: "file-signature" }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "title" }, [_vm._v("Confirmar")])
              ]
            )
          ]),
          _vm._v(" "),
          _c(
            "b-tabs",
            {
              attrs: { "no-key-nav": "", "nav-class": "d-none" },
              model: {
                value: _vm.formTabIndex,
                callback: function($$v) {
                  _vm.formTabIndex = $$v
                },
                expression: "formTabIndex"
              }
            },
            [
              _c(
                "b-tab",
                {
                  attrs: { "title-link-class": "" },
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function() {
                        return undefined
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-content" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "upload_file_group",
                            label: "Imagen de la obra",
                            "label-for": "upload_file",
                            "valid-feedback": "¡Listo!",
                            "invalid-feedback": !_vm.$v.form.tab1.file.required
                              ? "Elige una imagen."
                              : !_vm.$v.form.tab1.file.minImgDimensions
                              ? "Las dimensiones mínimas son 800x600 pixeles"
                              : !_vm.$v.form.tab1.file.maxFilesize
                              ? "La imágen debe pesar menos de 5MB."
                              : !_vm.$v.form.tab1.file.fileExtension
                              ? "La imagen debe ser jpg, jpeg ó png."
                              : "",
                            state: !_vm.$v.form.tab1.file.$invalid
                          }
                        },
                        [
                          _c("b-form-file", {
                            attrs: {
                              id: "upload_file",
                              state: !_vm.$v.form.tab1.file.$invalid,
                              placeholder:
                                "Haz click para elegir una imagen...",
                              accept: "image/png, image/jpeg",
                              "no-drop": ""
                            },
                            on: { change: _vm.onImageChange },
                            model: {
                              value: _vm.form.tab1.file,
                              callback: function($$v) {
                                _vm.$set(_vm.form.tab1, "file", $$v)
                              },
                              expression: "form.tab1.file"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "upload_title_group",
                            label: "Título de la obra",
                            "label-for": "upload_title",
                            "valid-feedback": "¡Listo!",
                            "invalid-feedback": "Escribe el título de la obra.",
                            state: !_vm.$v.form.tab1.title.$invalid
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "upload_title",
                              state: !_vm.$v.form.tab1.title.$invalid,
                              placeholder:
                                "Escribe aquí el título de la obra...",
                              size: "lg",
                              trim: ""
                            },
                            model: {
                              value: _vm.form.tab1.title,
                              callback: function($$v) {
                                _vm.$set(_vm.form.tab1, "title", $$v)
                              },
                              expression: "form.tab1.title"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "upload_description_group",
                            description:
                              "Una buena reflexión es muy importante.",
                            label: "Reflexión",
                            "label-for": "upload_description",
                            "valid-feedback": "¡Listo!",
                            "invalid-feedback":
                              "Escribe al menos 50 caracteres.",
                            state: !_vm.$v.form.tab1.description.$invalid
                          }
                        },
                        [
                          _c("b-form-textarea", {
                            attrs: {
                              id: "upload_description",
                              placeholder:
                                "Escribe aquí la descripción de la obra...",
                              rows: "3",
                              size: "lg",
                              state: !_vm.$v.form.tab1.description.$invalid
                            },
                            model: {
                              value: _vm.form.tab1.description,
                              callback: function($$v) {
                                _vm.$set(_vm.form.tab1, "description", $$v)
                              },
                              expression: "form.tab1.description"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-actions-container" }, [
                    _c("div", { staticClass: "form-action mr-2" }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-action ml-2" },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "flex-grow-shrink-0",
                            staticStyle: { "font-size": "100%" },
                            style:
                              "color:" +
                              _vm.$options.configs.buttonColor +
                              ";border-color:" +
                              _vm.$options.configs.buttonColor,
                            attrs: {
                              squared: "",
                              variant: "outline-success",
                              disabled: _vm.$v.form.tab1.$invalid
                            },
                            on: {
                              click: function($event) {
                                _vm.formTabIndex = 1
                              }
                            }
                          },
                          [
                            _c("div", { staticClass: "d-flex flex-column" }, [
                              _c("div", { staticClass: "pt-2" }, [
                                _c("strong", [_vm._v("SIGUIENTE")])
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticStyle: { "font-size": "200%" } },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "arrow-right" }
                                  })
                                ],
                                1
                              )
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _vm.$v.form.tab1.$invalid
                    ? _c("div", { staticClass: "form-error-container" }, [
                        _c("div", { staticClass: "form-error-label" }, [
                          _vm._v(
                            "\n            *Corrige los campos en rojo para continuar.\n          "
                          )
                        ])
                      ])
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _c(
                "b-tab",
                {
                  attrs: { title: "Tab 2", "title-link-class": "" },
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function() {
                        return undefined
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-content" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "p_name_group",
                            label: "Nombre del autor",
                            "label-for": "p_name",
                            "valid-feedback": "¡Listo!",
                            "invalid-feedback": "Escribe el nombre del autor.",
                            state: !_vm.$v.form.tab2.pName.$invalid
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "p_name",
                              state: !_vm.$v.form.tab2.pName.$invalid,
                              placeholder: "Introduce el nombre del autor...",
                              size: "lg",
                              trim: ""
                            },
                            model: {
                              value: _vm.form.tab2.pName,
                              callback: function($$v) {
                                _vm.$set(_vm.form.tab2, "pName", $$v)
                              },
                              expression: "form.tab2.pName"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "p_lastname_group",
                            label: "Apellido del autor",
                            "label-for": "p_lastname",
                            "valid-feedback": "¡Listo!",
                            "invalid-feedback":
                              "Escribe el apellido del autor.",
                            state: !_vm.$v.form.tab2.pLastname.$invalid
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "p_lastname",
                              state: !_vm.$v.form.tab2.pLastname.$invalid,
                              placeholder: "Introduce el apellido del autor...",
                              size: "lg",
                              trim: ""
                            },
                            model: {
                              value: _vm.form.tab2.pLastname,
                              callback: function($$v) {
                                _vm.$set(_vm.form.tab2, "pLastname", $$v)
                              },
                              expression: "form.tab2.pLastname"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "p_dob_group",
                            label: "Fecha de nacimiento",
                            "label-for": "p_dob",
                            "valid-feedback": "¡Listo!",
                            "invalid-feedback": "Elige la fecha de nacimiento.",
                            state: !_vm.$v.form.tab2.dob.$invalid
                          }
                        },
                        [
                          _c("b-form-datepicker", {
                            attrs: {
                              id: "p_dob",
                              max: new Date(),
                              "value-as-date": "",
                              state: !_vm.$v.form.tab2.dob.$invalid,
                              placeholder:
                                "Introduce la fecha de nacimiento del autor...",
                              size: "lg",
                              locale: "es",
                              "show-decade-nav": ""
                            },
                            model: {
                              value: _vm.form.tab2.dob,
                              callback: function($$v) {
                                _vm.$set(_vm.form.tab2, "dob", $$v)
                              },
                              expression: "form.tab2.dob"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "p_tel_group",
                            label: "Teléfono",
                            "label-for": "p_tel",
                            description:
                              "Opcional, para que podamos avisar si gana.",
                            state: _vm.form.tab2.phone.length > 0 ? true : null
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "p_tel",
                              state:
                                _vm.form.tab2.phone.length > 0 ? true : null,
                              placeholder: "+12 34 56789101",
                              size: "lg",
                              trim: "",
                              formatter: function(input) {
                                return input.replaceAll(/[^\d\s\(\)\-\+]/g, "")
                              }
                            },
                            model: {
                              value: _vm.form.tab2.phone,
                              callback: function($$v) {
                                _vm.$set(_vm.form.tab2, "phone", $$v)
                              },
                              expression: "form.tab2.phone"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "p_email_group",
                            label: "Correo",
                            "label-for": "p_email",
                            description:
                              "Opcional, para que podamos avisar si gana.",
                            state: _vm.$v.form.tab2.email.$invalid
                              ? false
                              : _vm.form.tab2.email.length > 0
                              ? true
                              : null
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "p_email",
                              state: _vm.$v.form.tab2.email.$invalid
                                ? false
                                : _vm.form.tab2.email.length > 0
                                ? true
                                : null,
                              placeholder: "Introduce el correo del autor...",
                              size: "lg",
                              trim: ""
                            },
                            model: {
                              value: _vm.form.tab2.email,
                              callback: function($$v) {
                                _vm.$set(_vm.form.tab2, "email", $$v)
                              },
                              expression: "form.tab2.email"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "p_postal_group",
                            label: "Código Postal",
                            "label-for": "p_postal",
                            state: !_vm.$v.form.tab2.postal.$invalid
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "p_postal",
                              state: !_vm.$v.form.tab2.postal.$invalid,
                              size: "lg",
                              trim: "",
                              formatter: function(input) {
                                return input.replaceAll(/[^\d]/g, "")
                              }
                            },
                            model: {
                              value: _vm.form.tab2.postal,
                              callback: function($$v) {
                                _vm.$set(_vm.form.tab2, "postal", $$v)
                              },
                              expression: "form.tab2.postal"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "p_city_group",
                            label: "Ciudad",
                            "label-for": "p_city",
                            state: !_vm.$v.form.tab2.city.$invalid
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "p_city",
                              state: !_vm.$v.form.tab2.city.$invalid,
                              size: "lg",
                              trim: ""
                            },
                            model: {
                              value: _vm.form.tab2.city,
                              callback: function($$v) {
                                _vm.$set(_vm.form.tab2, "city", $$v)
                              },
                              expression: "form.tab2.city"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "p_country_group",
                            label: "País",
                            "label-for": "p_country",
                            state: !_vm.$v.form.tab2.country.$invalid
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "p_country",
                              state: !_vm.$v.form.tab2.country.$invalid,
                              size: "lg",
                              trim: ""
                            },
                            model: {
                              value: _vm.form.tab2.country,
                              callback: function($$v) {
                                _vm.$set(_vm.form.tab2, "country", $$v)
                              },
                              expression: "form.tab2.country"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-actions-container" }, [
                    _c(
                      "div",
                      { staticClass: "form-action mr-2" },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "flex-grow-shrink-0",
                            staticStyle: { "font-size": "100%" },
                            style:
                              "color:" +
                              _vm.$options.configs.buttonColor +
                              ";border-color:" +
                              _vm.$options.configs.buttonColor,
                            attrs: { squared: "", variant: "outline-success" },
                            on: {
                              click: function($event) {
                                _vm.formTabIndex = 0
                              }
                            }
                          },
                          [
                            _c("div", { staticClass: "d-flex flex-column" }, [
                              _c("div", { staticClass: "pt-2" }, [
                                _c("strong", [_vm._v("REGRESAR")])
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticStyle: { "font-size": "200%" } },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "arrow-left" }
                                  })
                                ],
                                1
                              )
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-action ml-2" },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "flex-grow-shrink-0",
                            staticStyle: { "font-size": "100%" },
                            style:
                              "color:" +
                              _vm.$options.configs.buttonColor +
                              ";border-color:" +
                              _vm.$options.configs.buttonColor,
                            attrs: {
                              squared: "",
                              variant: "outline-success",
                              disabled: _vm.$v.form.tab2.$invalid
                            },
                            on: {
                              click: function($event) {
                                _vm.formTabIndex = 2
                              }
                            }
                          },
                          [
                            _c("div", { staticClass: "d-flex flex-column" }, [
                              _c("div", { staticClass: "pt-2" }, [
                                _c("strong", [_vm._v("SIGUIENTE")])
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticStyle: { "font-size": "200%" } },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "arrow-right" }
                                  })
                                ],
                                1
                              )
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _vm.$v.form.tab2.$invalid
                    ? _c("div", { staticClass: "form-error-container" }, [
                        _c("div", { staticClass: "form-error-label" }, [
                          _vm._v(
                            "\n            *Corrige los campos en rojo para continuar.\n          "
                          )
                        ])
                      ])
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _c(
                "b-tab",
                {
                  attrs: { title: "Tab 3", "title-link-class": "" },
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function() {
                        return undefined
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-content" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "t_name_group",
                            label: "Nombre del tutor",
                            "label-for": "t_name",
                            "valid-feedback": "¡Listo!",
                            "invalid-feedback": "Escribe el nombre del tutor.",
                            state: !_vm.$v.form.tab3.tName.$invalid
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "t_name",
                              state: !_vm.$v.form.tab3.tName.$invalid,
                              placeholder: "Introduce el nombre del tutor...",
                              size: "lg",
                              trim: ""
                            },
                            model: {
                              value: _vm.form.tab3.tName,
                              callback: function($$v) {
                                _vm.$set(_vm.form.tab3, "tName", $$v)
                              },
                              expression: "form.tab3.tName"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "t_lastname_group",
                            label: "Apellido del tutor",
                            "label-for": "t_lastname",
                            "valid-feedback": "¡Listo!",
                            "invalid-feedback":
                              "Escribe el apellido del tutor.",
                            state: !_vm.$v.form.tab3.tLastname.$invalid
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "t_lastname",
                              state: !_vm.$v.form.tab3.tLastname.$invalid,
                              placeholder: "Introduce el apellido del tutor...",
                              size: "lg",
                              trim: ""
                            },
                            model: {
                              value: _vm.form.tab3.tLastname,
                              callback: function($$v) {
                                _vm.$set(_vm.form.tab3, "tLastname", $$v)
                              },
                              expression: "form.tab3.tLastname"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-actions-container" }, [
                        _c(
                          "div",
                          { staticClass: "form-action mr-2" },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "flex-grow-shrink-0",
                                staticStyle: { "font-size": "100%" },
                                style:
                                  "color:" +
                                  _vm.$options.configs.buttonColor +
                                  ";border-color:" +
                                  _vm.$options.configs.buttonColor,
                                attrs: {
                                  squared: "",
                                  variant: "outline-success"
                                },
                                on: {
                                  click: function($event) {
                                    _vm.formTabIndex = 1
                                  }
                                }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "d-flex flex-column" },
                                  [
                                    _c("div", { staticClass: "pt-2" }, [
                                      _c("strong", [_vm._v("REGRESAR")])
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticStyle: { "font-size": "200%" } },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: { icon: "arrow-left" }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "form-action ml-2" },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "flex-grow-shrink-0",
                                staticStyle: { "font-size": "100%" },
                                style:
                                  "color:" +
                                  _vm.$options.configs.buttonColor +
                                  ";border-color:" +
                                  _vm.$options.configs.buttonColor,
                                attrs: {
                                  squared: "",
                                  variant: "outline-success",
                                  disabled: _vm.$v.form.tab3.$invalid
                                },
                                on: {
                                  click: function($event) {
                                    _vm.formTabIndex = 3
                                  }
                                }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "d-flex flex-column" },
                                  [
                                    _c("div", { staticClass: "pt-2" }, [
                                      _c("strong", [_vm._v("SIGUIENTE")])
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticStyle: { "font-size": "200%" } },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: { icon: "arrow-right" }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _vm.$v.form.tab3.$invalid
                        ? _c("div", { staticClass: "form-error-container" }, [
                            _c("div", { staticClass: "form-error-label" }, [
                              _vm._v(
                                "\n              *Corrige los campos en rojo para continuar.\n            "
                              )
                            ])
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "b-tab",
                {
                  attrs: { title: "Tab 4", "title-link-class": "" },
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function() {
                        return undefined
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-content" },
                    [
                      _c("div", { staticClass: "table-title" }, [
                        _vm._v("Verifica la información proporcionada")
                      ]),
                      _vm._v(" "),
                      _c("table", { staticClass: "confirmation-table" }, [
                        _c("tr", [
                          _c(
                            "td",
                            {
                              staticClass: "table-section-title",
                              attrs: { colspan: "2" }
                            },
                            [_vm._v("Dibujo")]
                          )
                        ]),
                        _vm._v(" "),
                        _c("tr", [
                          _c("td", [_vm._v("Imagen:")]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.form.tab1.file
                                  ? _vm.form.tab1.file.name
                                  : ""
                              )
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("tr", [
                          _c("td", [_vm._v("Título:")]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(_vm.form.tab1.title))])
                        ]),
                        _vm._v(" "),
                        _c("tr", [
                          _c("td", [_vm._v("Reflexión:")]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(_vm.form.tab1.description))])
                        ]),
                        _vm._v(" "),
                        _c("tr", [
                          _c(
                            "td",
                            {
                              staticClass: "table-section-title",
                              attrs: { colspan: "2" }
                            },
                            [_vm._v("Participante")]
                          )
                        ]),
                        _vm._v(" "),
                        _c("tr", [
                          _c("td", [_vm._v("Nombre Completo:")]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm.form.tab2.pName) +
                                " " +
                                _vm._s(_vm.form.tab2.pLastname)
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("tr", [
                          _c("td", [_vm._v("Fecha de Nacimiento:")]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.form.tab2.dob
                                    ? _vm.form.tab2.dob.toLocaleDateString(
                                        "es-ES",
                                        {
                                          weekday: "short",
                                          year: "numeric",
                                          month: "short",
                                          day: "numeric"
                                        }
                                      )
                                    : ""
                                ) +
                                "\n              "
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("tr", [
                          _c("td", [_vm._v("Teléfono:")]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(_vm.form.tab2.phone))])
                        ]),
                        _vm._v(" "),
                        _c("tr", [
                          _c("td", [_vm._v("Email:")]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(_vm.form.tab2.email))])
                        ]),
                        _vm._v(" "),
                        _c("tr", [
                          _c("td", [_vm._v("Código Postal:")]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(_vm.form.tab2.postal))])
                        ]),
                        _vm._v(" "),
                        _c("tr", [
                          _c("td", [_vm._v("Ciudad:")]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(_vm.form.tab2.city))])
                        ]),
                        _vm._v(" "),
                        _c("tr", [
                          _c("td", [_vm._v("País:")]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(_vm.form.tab2.country))])
                        ]),
                        _vm._v(" "),
                        _c("tr", [
                          _c(
                            "td",
                            {
                              staticClass: "table-section-title",
                              attrs: { colspan: "2" }
                            },
                            [_vm._v("Tutor")]
                          )
                        ]),
                        _vm._v(" "),
                        _c("tr", [
                          _c("td", [_vm._v("Nombre Completo:")]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm.form.tab3.tName) +
                                " " +
                                _vm._s(_vm.form.tab3.tLastname)
                            )
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "b-form-checkbox",
                        {
                          staticClass: "mb-3",
                          attrs: {
                            id: "checkbox_verified",
                            name: "checkbox_verified",
                            state: !_vm.$v.form.tab4.verified.$invalid
                          },
                          model: {
                            value: _vm.form.tab4.verified,
                            callback: function($$v) {
                              _vm.$set(_vm.form.tab4, "verified", $$v)
                            },
                            expression: "form.tab4.verified"
                          }
                        },
                        [
                          _vm._v(
                            "\n            Revisé que todos los datos son correctos y estoy consciente que\n            proveer datos incorrectos puede resultar en la descalificación de\n            este dibujo, como establecido en los\n            "
                          ),
                          _c(
                            "b-link",
                            {
                              directives: [
                                {
                                  name: "b-modal",
                                  rawName: "v-b-modal.modal-terms",
                                  modifiers: { "modal-terms": true }
                                }
                              ]
                            },
                            [_vm._v("Términos y Condiciones")]
                          ),
                          _vm._v(".\n          ")
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-checkbox",
                        {
                          staticClass: "mb-3",
                          attrs: {
                            id: "checkbox_color",
                            name: "checkbox_color",
                            state: !_vm.$v.form.tab4.colorCheck.$invalid
                          },
                          model: {
                            value: _vm.form.tab4.colorCheck,
                            callback: function($$v) {
                              _vm.$set(_vm.form.tab4, "colorCheck", $$v)
                            },
                            expression: "form.tab4.colorCheck"
                          }
                        },
                        [
                          _vm._v(
                            "\n            Entiendo y estoy de acuerdo que si el dibujo no cumple con los lineamientos de colores, este podrá ser modificado para su correcta impresión.\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: {
                            id: "checkbox_terms",
                            name: "checkbox_terms",
                            state: !_vm.$v.form.tab4.terms.$invalid
                          },
                          model: {
                            value: _vm.form.tab4.terms,
                            callback: function($$v) {
                              _vm.$set(_vm.form.tab4, "terms", $$v)
                            },
                            expression: "form.tab4.terms"
                          }
                        },
                        [
                          _vm._v(
                            "\n            He leído y estoy de acuerdo con los\n            "
                          ),
                          _c(
                            "b-link",
                            {
                              directives: [
                                {
                                  name: "b-modal",
                                  rawName: "v-b-modal.modal-terms",
                                  modifiers: { "modal-terms": true }
                                }
                              ]
                            },
                            [_vm._v("Términos y Condiciones")]
                          ),
                          _vm._v(
                            " al\n            igual que las\n            "
                          ),
                          _c(
                            "b-link",
                            {
                              directives: [
                                {
                                  name: "b-modal",
                                  rawName: "v-b-modal.modal-privacy",
                                  modifiers: { "modal-privacy": true }
                                }
                              ]
                            },
                            [_vm._v("Políticas de Privacidad")]
                          ),
                          _vm._v(".\n          ")
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-actions-container" }, [
                        _c(
                          "div",
                          { staticClass: "form-action mr-2" },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "flex-grow-shrink-0",
                                staticStyle: { "font-size": "100%" },
                                style:
                                  "color:" +
                                  _vm.$options.configs.buttonColor +
                                  ";border-color:" +
                                  _vm.$options.configs.buttonColor,
                                attrs: {
                                  squared: "",
                                  variant: "outline-success"
                                },
                                on: {
                                  click: function($event) {
                                    _vm.formTabIndex = 2
                                  }
                                }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "d-flex flex-column" },
                                  [
                                    _c("div", { staticClass: "pt-2" }, [
                                      _c("strong", [_vm._v("REGRESAR")])
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticStyle: { "font-size": "200%" } },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: { icon: "arrow-left" }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "form-action ml-2" },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "flex-grow-shrink-0",
                                staticStyle: { "font-size": "100%" },
                                style:
                                  "color:" +
                                  _vm.$options.configs.buttonColor +
                                  ";border-color:" +
                                  _vm.$options.configs.buttonColor,
                                attrs: {
                                  squared: "",
                                  variant: "outline-success",
                                  disabled:
                                    _vm.$v.form.tab4.$invalid || _vm.loading
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.onSubmit()
                                  }
                                }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "d-flex flex-column" },
                                  [
                                    _c("div", { staticClass: "pt-2" }, [
                                      _c("strong", [_vm._v("PARTICIPAR")])
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticStyle: { "font-size": "200%" } },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: { icon: "check-circle" }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _vm.$v.form.tab4.$invalid
                        ? _c("div", { staticClass: "form-error-container" }, [
                            _c("div", { staticClass: "form-error-label" }, [
                              _vm._v(
                                "\n              *Completa los campos faltantes para continuar.\n            "
                              )
                            ])
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modals-container" },
            [
              _c(
                "b-modal",
                {
                  attrs: {
                    id: "modal-terms",
                    size: "lg",
                    centered: "",
                    "header-class": "custom-modal-header",
                    "ok-title": "Cerrar",
                    scrollable: ""
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "modal-header",
                      fn: function(ref) {
                        var close = ref.close
                        return [
                          _c("h1", [_vm._v("Términos y Condiciones")]),
                          _vm._v(" "),
                          _c("b-button-close", {
                            on: {
                              click: function($event) {
                                return close()
                              }
                            }
                          })
                        ]
                      }
                    },
                    {
                      key: "modal-footer",
                      fn: function(ref) {
                        var ok = ref.ok
                        return [
                          _c(
                            "b-button",
                            {
                              attrs: { size: "sm", variant: "success" },
                              on: {
                                click: function($event) {
                                  return ok()
                                }
                              }
                            },
                            [_vm._v("Cerrar")]
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "custom-modal-content",
                    domProps: { innerHTML: _vm._s(_vm.$options.configs.terms) }
                  })
                ]
              ),
              _vm._v(" "),
              _c(
                "b-modal",
                {
                  attrs: {
                    id: "modal-privacy",
                    size: "lg",
                    centered: "",
                    "header-class": "custom-modal-header",
                    "ok-title": "Cerrar",
                    scrollable: ""
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "modal-header",
                      fn: function(ref) {
                        var close = ref.close
                        return [
                          _c("h1", [_vm._v("Política de Privacidad")]),
                          _vm._v(" "),
                          _c("b-button-close", {
                            on: {
                              click: function($event) {
                                return close()
                              }
                            }
                          })
                        ]
                      }
                    },
                    {
                      key: "modal-footer",
                      fn: function(ref) {
                        var ok = ref.ok
                        return [
                          _c(
                            "b-button",
                            {
                              attrs: { size: "sm", variant: "success" },
                              on: {
                                click: function($event) {
                                  return ok()
                                }
                              }
                            },
                            [_vm._v("Cerrar")]
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "custom-modal-content",
                    domProps: {
                      innerHTML: _vm._s(_vm.$options.configs.privacy)
                    }
                  })
                ]
              ),
              _vm._v(" "),
              _c(
                "b-modal",
                {
                  attrs: {
                    id: "modal-success",
                    size: "sm",
                    centered: "",
                    "header-class": "custom-modal-header",
                    "ok-title": "Cerrar"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "modal-header",
                      fn: function(ref) {
                        var close = ref.close
                        return [
                          _c("h1", [_vm._v("¡Éxito!")]),
                          _vm._v(" "),
                          _c("b-button-close", {
                            on: {
                              click: function($event) {
                                return close()
                              }
                            }
                          })
                        ]
                      }
                    },
                    {
                      key: "modal-footer",
                      fn: function(ref) {
                        return [
                          _c(
                            "b-button",
                            {
                              attrs: { size: "sm", variant: "success" },
                              on: {
                                click: function($event) {
                                  return _vm.goToParticipation(
                                    _vm.participationId
                                  )
                                }
                              }
                            },
                            [_vm._v("Cerrar")]
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _vm._v(" "),
                  _c("div", { staticClass: "custom-modal-content" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-content-center align-items-center pb-5",
                        staticStyle: {
                          "font-size": "5rem",
                          color: "rgb(4,155,4)"
                        }
                      },
                      [
                        _c(
                          "div",
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: "check-circle" }
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("p", [_vm._v("Tu participacion ha sido registrada.")]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "\n            Después de haber sido aprobada por un moderador, el dibujo será\n            publicado en la sección de Dibujos.\n          "
                      )
                    ])
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "b-modal",
                {
                  attrs: {
                    id: "modal-error",
                    size: "sm",
                    centered: "",
                    "header-class": "custom-modal-header",
                    "ok-title": "Cerrar"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "modal-header",
                      fn: function(ref) {
                        var close = ref.close
                        return [
                          _c("h1", [_vm._v("Error")]),
                          _vm._v(" "),
                          _c("b-button-close", {
                            on: {
                              click: function($event) {
                                return close()
                              }
                            }
                          })
                        ]
                      }
                    },
                    {
                      key: "modal-footer",
                      fn: function(ref) {
                        var ok = ref.ok
                        return [
                          _c(
                            "b-button",
                            {
                              attrs: { size: "sm", variant: "success" },
                              on: {
                                click: function($event) {
                                  return ok()
                                }
                              }
                            },
                            [_vm._v("Cerrar")]
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _vm._v(" "),
                  _c("div", { staticClass: "custom-modal-content" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-content-center align-items-center pb-5",
                        staticStyle: { "font-size": "5rem", color: "#dc3545" }
                      },
                      [
                        _c(
                          "div",
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: "exclamation-circle" }
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("p", [_vm._v("Hubo un problema con tu solicitud:")]),
                    _vm._v(" "),
                    _c(
                      "ul",
                      _vm._l(_vm.errors, function(error, name, index) {
                        return _c("li", { key: index }, [
                          _vm._v(_vm._s(name) + ":" + _vm._s(error))
                        ])
                      }),
                      0
                    )
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }