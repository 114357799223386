<style lang="scss" scoped></style>

<template>
  <div class="content-inner" v-if="title && title != ''">
    <div class="bio-image-container mx-md-5" :class="imgRight ? 'img-right' : ''">
      <div
        class="img-bg bg-img"
        :style="`background-image: url('${imgUrl}');`"
      ></div>
      <div class="title" v-html="title" :style="{ color: titleColor }"></div>
    </div>
    <div
      class="bio-container mx-md-5"
      :class="imgRight ? 'img-right' : ''"
      v-html="text"
    ></div>
  </div>
</template>

<script>
export default {
  name: "JuryContainer",
  props: {
    title: String,
    titleColor: String,
    text: String,
    imgUrl: String,
    imgRight: Boolean,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
