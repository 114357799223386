<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    style="margin: auto; background: rgba(241, 242, 243, 0); display: block;"
    width="200px"
    height="200px"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
  >
    <circle
      cx="50"
      cy="50"
      r="30"
      stroke="rgba(148, 209, 55, 0)"
      stroke-width="10"
      fill="none"
    ></circle>
    <circle
      cx="50"
      cy="50"
      r="30"
      stroke="#5fa347"
      stroke-width="8"
      stroke-linecap="round"
      fill="none"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        repeatCount="indefinite"
        dur="1.7857142857142856s"
        values="0 50 50;180 50 50;720 50 50"
        keyTimes="0;0.5;1"
      ></animateTransform>
      <animate
        attributeName="stroke-dasharray"
        repeatCount="indefinite"
        dur="1.7857142857142856s"
        values="3.7699111843077517 184.72564803107983;135.71680263507906 52.77875658030851;3.7699111843077517 184.72564803107983"
        keyTimes="0;0.5;1"
      ></animate>
    </circle>
  </svg>
</template>

<script>
export default {
    name: "ImageSpinnerIcon",
    components: {},
    props: [],
    data() {
        return {
        }
    },
    methods: {

    }
}
</script>
