var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "img-container",
      on: {
        click: function($event) {
          return _vm.$emit("click")
        }
      }
    },
    [
      !_vm.loaded
        ? _c(
            "div",
            { staticClass: "spinner-container" },
            [_c("image-spinner-icon", { staticClass: "spinner" })],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("b-img", {
        staticClass: "img",
        attrs: { "fluid-grow": "", src: _vm.src, alt: _vm.title },
        on: {
          load: function($event) {
            return _vm.onLoad()
          }
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "badge-container" }, [
        _vm.publicVote
          ? _c(
              "div",
              { staticClass: "image-badge public-vote" },
              [
                _c(
                  "b-badge",
                  {
                    attrs: {
                      pill: "",
                      variant: "light",
                      id: "public_vote_" + _vm.index
                    }
                  },
                  [
                    _vm._v(_vm._s(_vm.publicVote) + " "),
                    _c("b-icon", {
                      attrs: { color: "#ff8800", icon: "star-fill" }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("b-tooltip", {
                  attrs: {
                    target: "public_vote_" + _vm.index,
                    title: "Tu votación.",
                    variant: "light"
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.createdByMe
          ? _c(
              "div",
              { staticClass: "image-badge by-me" },
              [
                _c(
                  "b-badge",
                  {
                    attrs: {
                      pill: "",
                      variant: "light",
                      id: "created_by_me_" + _vm.index
                    }
                  },
                  [
                    _c("b-icon", {
                      attrs: { color: "#327731", icon: "person-fill" }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("b-tooltip", {
                  attrs: {
                    target: "created_by_me_" + _vm.index,
                    title: "Tu subiste este dibujo.",
                    variant: "light"
                  }
                })
              ],
              1
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }