<style lang="scss" scoped></style>

<template>
  <div
    class="step-container bg-img"
    :class="rightAligned ? 'right-aligned' : ''"
    :style="
      `background-image: url('${bgUrl}');background-size:${bgSize};background-position:${bgPosition};`
    "
  >
    <div class="inner-container" >
      <div
        class="title"
        v-html="title"
        :style="{'color': titleColor }"
      ></div>
      <div class="text" v-html="text"></div>
      <div
         v-if="title2"
        class="title"
        v-html="title2"
        :style="{'color': title2Color }"
      ></div>
      <div v-if="text2" class="text" v-html="text2"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BasisStep",
  props: {
    title: String,
    titleColor: String,
    title2: String,
    title2Color: String,
    text: String,
    text2: String,
    bgUrl: String,
    bgPosition: String,
    bgSize: String,
    rightAligned: Boolean
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
