import Vue from "vue";
import VueRouter from "vue-router";

import Home from "./pages/Home.vue";
import ContestBasis from "./pages/ContestBasis.vue"
import Jury from "./pages/Jury.vue"
import Drawings from "./pages/Drawings.vue"
import MyDrawings from "./pages/MyDrawings.vue"
import UploadDrawing from "./pages/UploadDrawing.vue"

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: [
    { path: "/", name: "home", component: Home },
    { path: "/bases-del-concurso", name: "bases", component: ContestBasis },
    { path: "/los-jurados", name: "jury", component: Jury },
    { path: "/dibujos/:id?", name: "drawings", component: Drawings },
    { path: "/mis-dibujos/:id?", name: "my_drawings", component: MyDrawings },
    { path: "/sube-tu-dibujo", name: "uploadDrawing", component: UploadDrawing, meta: { requiresAuth: true } },
    { path: '*', redirect: { name: 'home' }}
  ],
  scrollBehavior (to) {
    if (to.hash) {
      console.log("going to hash");
      return {
        selector: to.hash,
        behavior: 'smooth'
      };
    }
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  if (to.name == 'uploadDrawing' && (!window.configs.user.isLogged || !window.configs.user.contestAvailable)) next({ name: 'home' })
  else next()
})

export default router;
