var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "list-item-container" }, [
    _c(
      "div",
      {
        staticClass: "img-container",
        on: {
          click: function($event) {
            return _vm.$emit("click")
          }
        }
      },
      [
        !_vm.loaded
          ? _c(
              "div",
              { staticClass: "spinner-container" },
              [_c("image-spinner-icon", { staticClass: "spinner" })],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("b-img", {
          staticClass: "img",
          attrs: { src: _vm.src, alt: _vm.title },
          on: {
            load: function($event) {
              return _vm.onLoad()
            }
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "info-container" }, [
      _c("div", { staticClass: "data-container" }, [
        _c("div", { staticClass: "title" }, [_vm._v("Título:")]),
        _vm._v(" "),
        _c("div", { staticClass: "data" }, [_vm._v(_vm._s(_vm.title))])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "data-container" }, [
        _c("div", { staticClass: "title" }, [_vm._v("Reflexión:")]),
        _vm._v(" "),
        _c("div", { staticClass: "data" }, [_vm._v(_vm._s(_vm.description))])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "data-container" }, [
        _c("div", { staticClass: "title" }, [_vm._v("Concurso:")]),
        _vm._v(" "),
        _c("div", { staticClass: "data" }, [
          _vm._v("\n        " + _vm._s(_vm.contest.name) + "\n      ")
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "data-container" }, [
        _c("div", { staticClass: "title" }, [_vm._v("Categoría:")]),
        _vm._v(" "),
        _c("div", { staticClass: "data" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.category.name) +
              " (" +
              _vm._s(_vm.category.min_age) +
              "-" +
              _vm._s(_vm.category.max_age) +
              "años)\n      "
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "data-container" }, [
        _c("div", { staticClass: "title" }, [_vm._v("Estado:")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "data" },
          [
            _c(
              "b-badge",
              { attrs: { pill: "", variant: _vm.translatedStatus.color } },
              [_vm._v(_vm._s(_vm.translatedStatus.label))]
            )
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }