var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c("page-header", {
        attrs: {
          title: _vm.$options.configs.header.title,
          "header-class": "subir",
          subtitle: "",
          "bg-url": _vm.assets.headerBgUrl,
          "bg-position": _vm.$options.configs.header.bgPosition,
          "bg-size": _vm.$options.configs.header.bgSize,
          "title-color": _vm.$options.configs.header.titleColor
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "content subir" }, [
        _c("div", { staticClass: "content-outer" }, [
          _c(
            "div",
            { staticClass: "content-inner" },
            [_c("participation-form")],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c("page-footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }