import { render, staticRenderFns } from "./JuryContainer.vue?vue&type=template&id=41081ff3&scoped=true&"
import script from "./JuryContainer.vue?vue&type=script&lang=js&"
export * from "./JuryContainer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41081ff3",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\edomo\\Documents\\concurso_dibujo_2\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('41081ff3')) {
      api.createRecord('41081ff3', component.options)
    } else {
      api.reload('41081ff3', component.options)
    }
    module.hot.accept("./JuryContainer.vue?vue&type=template&id=41081ff3&scoped=true&", function () {
      api.rerender('41081ff3', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "assets/js/components/jury/JuryContainer.vue"
export default component.exports