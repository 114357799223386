<style lang="scss" scoped>
.overflow-hidden {
  overflow: hidden;
}
.img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.img-container {
  position: relative;
  width: 100%;
  height: 100%;
  transition: box-shadow 250ms;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  &:hover {
    box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.2),
      0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
}
.spinner-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .spinner {
    width: 30%;
    height: 30%;
  }
}
.badge-container {
  font-size: 120%;
  position: absolute;
  bottom: 0.5em;
  right: 0.5em;
  display: flex;
  .image-badge {
    margin-left: 0.5em;
    // &.public-vote {
    // }
    // &.my-me {
    // }
  }
}
</style>

<template>
  <div class="img-container" @click="$emit('click')">
    <div class="spinner-container" v-if="!loaded">
      <image-spinner-icon class="spinner"></image-spinner-icon>
    </div>
    <b-img
      class="img"
      fluid-grow
      :src="src"
      :alt="title"
      @load="onLoad()"
    ></b-img>
    <div class="badge-container">
      <div class="image-badge public-vote" v-if="publicVote">
        <b-badge pill variant="light" :id="'public_vote_'+index"
          >{{ publicVote }} <b-icon color="#ff8800" icon="star-fill"></b-icon
        ></b-badge>
        <b-tooltip :target="'public_vote_'+index" title="Tu votación." variant="light"></b-tooltip>
      </div>
      <div class="image-badge by-me" v-if="createdByMe">
        <b-badge pill variant="light" :id="'created_by_me_'+index"
          ><b-icon color="#327731" icon="person-fill"></b-icon
        ></b-badge>
        <b-tooltip :target="'created_by_me_'+index" title="Tu subiste este dibujo." variant="light"></b-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import ImageSpinnerIcon from "../Icons/ImageSpinnerIcon.vue";
export default {
  name: "DrawingListItem",
  components: { ImageSpinnerIcon },
  props: ["title", "src", "publicVote", "createdByMe", "index"],
  data() {
    return {
      loaded: false,
    };
  },
  methods: {
    onLoad() {
      this.loaded = true;
    },
  },
};
</script>
