<style lang="scss" scoped>
.loader-container {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  backdrop-filter: blur(3px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  .spinner-container {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .spinner {
      width: 30%;
      height: 30%;
    }
  }
}
</style>

<template>
  <div class="loader-container">
    <div class="spinner-container">
      <image-spinner-icon class="spinner"></image-spinner-icon>
    </div>
  </div>
</template>

<script>
import ImageSpinnerIcon from '../Icons/ImageSpinnerIcon.vue'
export default {
  name: "Loader",
  components: {ImageSpinnerIcon},
  data() {
    return {};
  },
  methods: {},
};
</script>
