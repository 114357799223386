var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", { class: _vm.headerClass }, [
    _c(
      "div",
      {
        staticClass: "title-container bg-img",
        style:
          "background-image: url(" +
          _vm.bgUrl +
          ");background-position: " +
          _vm.bgPosition +
          ";background-size: " +
          _vm.bgSize +
          ";"
      },
      [
        _c("div", {
          staticClass: "title",
          style: "color:" + _vm.titleColor + ";",
          domProps: { innerHTML: _vm._s(_vm.title) }
        }),
        _vm._v(" "),
        _c("div", {
          staticClass: "subtitle",
          style: "color:" + _vm.subtitleColor + ";",
          domProps: { innerHTML: _vm._s(_vm.subtitle) }
        }),
        _vm._v(" "),
        _vm.extraSubtitle
          ? _c("div", {
              staticClass: "extra-subtitle",
              domProps: { innerHTML: _vm._s(_vm.extraSubtitle) }
            })
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }