var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-modal", {
        attrs: {
          id: "registration-modal",
          title: "Registrar",
          "header-bg-variant": "info",
          "header-text-variant": "light",
          "header-class": "align-items-baseline position-relative",
          "hide-footer": "",
          centered: "",
          "no-stacking": ""
        },
        scopedSlots: _vm._u([
          {
            key: "modal-header",
            fn: function(ref) {
              var close = ref.close
              return [
                _c("b-icon", {
                  staticClass: "mr-2",
                  attrs: { icon: "key", "aria-hidden": "true" }
                }),
                _vm._v(" "),
                _c("h5", [_vm._v("Crear Cuenta")]),
                _vm._v(" "),
                _c("b-button-close", {
                  on: {
                    click: function($event) {
                      return close()
                    }
                  }
                })
              ]
            }
          },
          {
            key: "default",
            fn: function() {
              return [
                _c("loader", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.loading,
                      expression: "loading"
                    }
                  ]
                }),
                _vm._v(" "),
                _c("p", { staticClass: "mb-2" }, [
                  _vm._v("¡Regístrate para participar o votar!")
                ]),
                _vm._v(" "),
                _c(
                  "b-form",
                  {
                    attrs: { action: "/registration" },
                    on: {
                      submit: function($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.onSubmit($event)
                      }
                    }
                  },
                  [
                    _c(
                      "b-form-group",
                      {
                        class: _vm.$v.form.username.$invalid ? "mb-0" : "",
                        attrs: {
                          "label-size": "md",
                          label: "Correo",
                          "label-for": "registration-email"
                        }
                      },
                      [
                        _c(
                          "b-input-group",
                          { attrs: { size: "md" } },
                          [
                            _c(
                              "b-input-group-prepend",
                              [
                                _c(
                                  "b-input-group-text",
                                  [_c("b-icon", { attrs: { icon: "person" } })],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("b-form-input", {
                              attrs: {
                                id: "registration-email",
                                state: _vm.$v.form.username.$invalid
                                  ? false
                                  : null,
                                autocomplete: "email"
                              },
                              model: {
                                value: _vm.form.username,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "username", $$v)
                                },
                                expression: "form.username"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        !_vm.$v.form.username.email ||
                        !_vm.$v.form.username.required
                          ? _c(
                              "div",
                              { staticClass: "text-muted text-right" },
                              [
                                _vm._v(
                                  "\n            Debe ser un correo válido.\n          "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.$v.form.username.unique &&
                        _vm.$v.form.username.email &&
                        _vm.$v.form.username.required &&
                        !_vm.fetchingUser
                          ? _c(
                              "div",
                              { staticClass: "text-muted text-right" },
                              [
                                _vm._v(
                                  "\n            Este correo ya ha sido registrado.\n          "
                                )
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-form-group",
                      {
                        class: _vm.$v.form.name.$invalid ? "mb-0" : "",
                        attrs: {
                          "label-size": "md",
                          label: "Nombre",
                          "label-for": "registration-name"
                        }
                      },
                      [
                        _c(
                          "b-input-group",
                          { attrs: { size: "md" } },
                          [
                            _c(
                              "b-input-group-prepend",
                              [
                                _c(
                                  "b-input-group-text",
                                  [_c("b-icon", { attrs: { icon: "person" } })],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("b-form-input", {
                              attrs: {
                                id: "registration-name",
                                state: _vm.$v.form.name.$invalid ? false : null,
                                autocomplete: "given-name"
                              },
                              model: {
                                value: _vm.form.name,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "name", $$v)
                                },
                                expression: "form.name"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.$v.form.name.$invalid
                          ? _c(
                              "div",
                              { staticClass: "text-muted text-right" },
                              [_vm._v("\n            Requerido.\n          ")]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-form-group",
                      {
                        class: _vm.$v.form.lastname.$invalid ? "mb-0" : "",
                        attrs: {
                          "label-size": "md",
                          label: "Apellido",
                          "label-for": "registration-lastname"
                        }
                      },
                      [
                        _c(
                          "b-input-group",
                          { attrs: { size: "md" } },
                          [
                            _c(
                              "b-input-group-prepend",
                              [
                                _c(
                                  "b-input-group-text",
                                  [_c("b-icon", { attrs: { icon: "person" } })],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("b-form-input", {
                              attrs: {
                                id: "registration-lastname",
                                state: _vm.$v.form.lastname.$invalid
                                  ? false
                                  : null,
                                autocomplete: "family-name"
                              },
                              model: {
                                value: _vm.form.lastname,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "lastname", $$v)
                                },
                                expression: "form.lastname"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.$v.form.lastname.$invalid
                          ? _c(
                              "div",
                              { staticClass: "text-muted text-right" },
                              [_vm._v("\n            Requerido.\n          ")]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-form-group",
                      {
                        class: _vm.$v.form.password.$invalid ? "mb-0" : "",
                        attrs: {
                          "label-size": "md",
                          label: "Contraseña",
                          "label-for": "registration-password"
                        }
                      },
                      [
                        _c(
                          "b-input-group",
                          { attrs: { size: "md" } },
                          [
                            _c(
                              "b-input-group-prepend",
                              [
                                _c(
                                  "b-input-group-text",
                                  [_c("b-icon", { attrs: { icon: "key" } })],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("b-form-input", {
                              attrs: {
                                id: "registration-password",
                                type: "password",
                                state: _vm.$v.form.password.$invalid
                                  ? false
                                  : null,
                                autocomplete: "new-password"
                              },
                              model: {
                                value: _vm.form.password,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "password", $$v)
                                },
                                expression: "form.password"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        !_vm.$v.form.password.required
                          ? _c(
                              "div",
                              { staticClass: "text-muted text-right" },
                              [_vm._v("\n            Requerida\n          ")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.$v.form.password.minLengthValue &&
                        _vm.$v.form.password.required
                          ? _c(
                              "div",
                              { staticClass: "text-muted text-right" },
                              [
                                _vm._v(
                                  "\n            Debe contener al menos 6 caracteres.\n          "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.$v.form.password.regex &&
                        _vm.$v.form.password.required &&
                        _vm.$v.form.password.minLengthValue
                          ? _c(
                              "div",
                              { staticClass: "text-muted text-right" },
                              [
                                _vm._v(
                                  "\n            Usa al menos una mayúscula, una minúscula y un número.\n          "
                                )
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-form-group",
                      {
                        class: _vm.$v.form.password2.$invalid ? "mb-0" : "",
                        attrs: {
                          "label-size": "md",
                          label: "Repetir Contraseña",
                          "label-for": "registration-password2"
                        }
                      },
                      [
                        _c(
                          "b-input-group",
                          { attrs: { size: "md" } },
                          [
                            _c(
                              "b-input-group-prepend",
                              [
                                _c(
                                  "b-input-group-text",
                                  [_c("b-icon", { attrs: { icon: "key" } })],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("b-form-input", {
                              attrs: {
                                id: "registration-password2",
                                type: "password",
                                state: _vm.$v.form.password2.$invalid
                                  ? false
                                  : null,
                                autocomplete: "new-password"
                              },
                              model: {
                                value: _vm.form.password2,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "password2", $$v)
                                },
                                expression: "form.password2"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        !_vm.$v.form.password2.required
                          ? _c(
                              "div",
                              { staticClass: "text-muted text-right" },
                              [_vm._v("\n            Requerida\n          ")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.$v.form.password2.sameAs &&
                        _vm.$v.form.password2.required
                          ? _c(
                              "div",
                              { staticClass: "text-muted text-right" },
                              [
                                _vm._v(
                                  "\n            Las contraseñas no coinciden.\n          "
                                )
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", [
                      _c(
                        "div",
                        {
                          staticClass: "d-flex justify-content-center",
                          class: _vm.$v.form.password2.$invalid ? "mb-2" : ""
                        },
                        [
                          _c("vue-recaptcha", {
                            ref: "recaptcha_reg",
                            attrs: {
                              sitekey: _vm.$options.recaptchaSiteKey,
                              loadRecaptchaScript: true
                            },
                            on: {
                              verify: _vm.onCaptchaVerified,
                              expired: _vm.onCaptchaExpired,
                              error: _vm.onCaptchaError
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.$v.form.captcha.$invalid
                        ? _c("div", { staticClass: "text-muted text-center" }, [
                            _vm._v(
                              "\n            Debes completar el captcha. "
                            ),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "text-decoration": "underline",
                                  cursor: "pointer"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.onRecaptchaReset()
                                  }
                                }
                              },
                              [_vm._v("Resetear captcha")]
                            )
                          ])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _vm.submitError
                      ? _c(
                          "div",
                          { staticClass: "text-danger text-center py-2" },
                          [
                            _vm._v(
                              "\n          Hubo un error con tu solicitud.\n        "
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "mt-4 d-flex" },
                      [
                        _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "b-modal",
                                rawName: "v-b-modal.login-modal",
                                modifiers: { "login-modal": true }
                              }
                            ],
                            staticClass: "mr-2",
                            attrs: {
                              type: "button",
                              variant: "info",
                              disabled: _vm.loading
                            }
                          },
                          [
                            _c("b-icon", {
                              staticClass: "mr-2",
                              attrs: {
                                icon: "arrow-return-left",
                                "aria-hidden": "true"
                              }
                            }),
                            _vm._v("Regresar")
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-button",
                          {
                            attrs: {
                              type: "submit",
                              variant: "success",
                              disabled: _vm.$v.form.$invalid || _vm.loading
                            }
                          },
                          [
                            _c("b-icon", {
                              staticClass: "mr-2",
                              attrs: {
                                icon: "person-plus",
                                "aria-hidden": "true"
                              }
                            }),
                            _vm._v("Registrarme")
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "modal-footer",
            fn: function(ref) {
              var close = ref.close
              return [
                _c(
                  "div",
                  { staticClass: "w-100" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "float-right",
                        attrs: { variant: "primary", size: "sm" },
                        on: {
                          click: function($event) {
                            return close()
                          }
                        }
                      },
                      [_vm._v("\n          Close\n        ")]
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c("success-modal", {
        attrs: {
          content:
            "Gracias por registrarte, te llegará un correo con instrucciones para activar tu cuenta.",
          id: "registration_success_modal"
        },
        on: {
          click: function($event) {
            return _vm.onSuccessModalClick()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }