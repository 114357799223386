var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c("page-header", {
        attrs: {
          title: _vm.$options.configs.title,
          "header-class": "bases",
          subtitle: _vm.$options.configs.subTitle,
          "bg-url": _vm.assets.headerBgUrl,
          "bg-position": "left bottom",
          "bg-size": "40%",
          "title-color": _vm.$options.configs.titleColor
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content bases" },
        [
          _c(
            "div",
            { staticClass: "more-info-container" },
            _vm._l(_vm.$options.configs.intro, function(intro, index) {
              return _c("basis-info", {
                key: index,
                attrs: {
                  title: intro.introTitle,
                  text: intro.introContent,
                  titleColor: intro.titleColor
                }
              })
            }),
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "hero-title" }, [
            _c(
              "div",
              {
                staticClass: "hero-text",
                style: "color:" + _vm.$options.configs.heroColor + ";"
              },
              [_vm._v("\n        ¿Cómo Participar?\n      ")]
            )
          ]),
          _vm._v(" "),
          _vm._l(_vm.$options.configs.steps, function(step, index) {
            return _c("basis-step", {
              key: index,
              attrs: {
                title: step.stepTitle,
                text: step.stepContent,
                title2: step.title2,
                text2: step.text2,
                bgUrl: step.bgImage,
                bgPosition: step.bgPosition,
                bgSize: step.bgSize,
                titleColor: step.titleColor,
                title2Color: step.title2Color,
                rightAligned: step.rightAligned === "1"
              }
            })
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "more-info-container" },
            _vm._l(_vm.$options.configs.moreInfo, function(info, index) {
              return _c("basis-info", {
                key: index,
                attrs: {
                  title: info.moreInfoTitle,
                  text: info.moreInfoContent,
                  titleColor: info.titleColor
                }
              })
            }),
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "more-info-container pt-0" }, [
            _c(
              "div",
              { staticClass: "content-actions d-flex px-md-5 mx-md-5" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "flex-grow-shrink-0 mr-2",
                    staticStyle: { "font-size": "80%" },
                    style:
                      "color:" +
                      _vm.$options.configs.buttonColor +
                      ";border-color:" +
                      _vm.$options.configs.buttonColor,
                    attrs: {
                      type: "button",
                      to: { name: "jury" },
                      squared: "",
                      variant: "outline-success"
                    }
                  },
                  [
                    _c("div", { staticClass: "d-flex flex-column" }, [
                      _c("div", { staticClass: "pt-2" }, [
                        _c("strong", [_vm._v("LOS JURADOS")])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { "font-size": "200%" } },
                        [_c("font-awesome-icon", { attrs: { icon: "users" } })],
                        1
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-button",
                  {
                    directives: [
                      {
                        name: "b-toggle",
                        rawName: "v-b-toggle.sidebar-menu",
                        modifiers: { "sidebar-menu": true }
                      }
                    ],
                    staticClass: "flex-grow-shrink-0",
                    staticStyle: { "font-size": "80%" },
                    style:
                      "color:" +
                      _vm.$options.configs.buttonColor +
                      ";border-color:" +
                      _vm.$options.configs.buttonColor,
                    attrs: { squared: "", variant: "outline-success" }
                  },
                  [
                    _c("div", { staticClass: "d-flex flex-column" }, [
                      _c("div", { staticClass: "pt-2" }, [
                        _c("strong", [_vm._v("EXPLORA EL MENÚ")])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { "font-size": "200%" } },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "compass" }
                          })
                        ],
                        1
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-button",
                  {
                    directives: [
                      {
                        name: "b-modal",
                        rawName: "v-b-modal",
                        value:
                          _vm.$options.user && _vm.$options.user.isLogged
                            ? ""
                            : "login-modal",
                        expression:
                          "$options.user && $options.user.isLogged ? '' : 'login-modal'"
                      }
                    ],
                    staticClass: "flex-grow-shrink-0 ml-2",
                    staticStyle: { "font-size": "80%" },
                    style:
                      "color:" +
                      _vm.$options.configs.buttonColor +
                      ";border-color:" +
                      _vm.$options.configs.buttonColor,
                    attrs: {
                      to:
                        _vm.$options.user && _vm.$options.user.isLogged
                          ? { name: "uploadDrawing" }
                          : {},
                      squared: "",
                      variant: "outline-success"
                    }
                  },
                  [
                    _c("div", { staticClass: "d-flex flex-column" }, [
                      _c("div", { staticClass: "pt-2" }, [
                        _c("strong", [_vm._v("PARTICIPAR")])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { "font-size": "200%" } },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "vote-yea" }
                          })
                        ],
                        1
                      )
                    ])
                  ]
                )
              ],
              1
            )
          ])
        ],
        2
      ),
      _vm._v(" "),
      _c("page-footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }