<style lang="scss" scoped>
.form-container {
  display: flex;
  position: relative;
  .image-preview-container {
    width: 50%;
    @media only screen and (max-width: 767px) {
      display: none;
    }
    height: 30em;
    .no-image {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 250%;
      background-color: lightgray;
      color: rgb(128, 128, 128);
    }
    .image-preview {
      width: 100%;
      height: 100%;
    }
  }
  .form-container-inner {
    width: 50%;
    @media only screen and (max-width: 767px) {
      width: 100%
    }
  
    .form-actions-container {
      display: flex;
      padding-top: 2em;
      .form-action {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
      }
    }
    .form-error-container {
      padding-top: 0.5em;
      font-size: 70%;
      .form-error-label {
        text-align: center;
        color: #dc3545;
      }
    }
    .tabs-header-container {
      display: flex;
      align-items: center;
      .tabs-header-element {
        position: relative;
        flex: 1 1 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #cccccc;
        transition: transform 0.2s ease;
        &.valid {
          color: rgb(7, 95, 7);
        }
        &.current {
          color: rgb(4, 155, 4);
          transform: scale(1.5);
        }
        .icon {
          font-size: 150%;
        }
        .check-icon {
          position: absolute;
          top: 0px;
          right: 20%;
          font-size: 80%;
        }
        // .title {
        // }
      }
      .tabs-header-separator {
        font-size: 80%;
        color: #cccccc;
        &.valid {
          color: rgb(7, 95, 7);
        }
      }
    }
    .table-title {
      text-align: center;
    }
    table {
      &.confirmation-table {
        font-size: 70%;
        width: 100%;
        margin-bottom: 2em;
        .table-section-title {
          text-align: center;
          padding-top: 1em;
          font-size: 120%;
          font-weight: bold;
        }
        td {
          &:not(.table-section-title) {
            &:nth-child(1) {
              font-weight: bold;
              width: 50%;
              text-align: right;
              padding-right: 1em;
            }
            &:nth-child(2) {
              padding-left: 1em;
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.custom-file-label {
  color: #6c757d;
  font-size: 90%;
}

.custom-modal-header {
  font-size: 150%;
  border: none;
}
.custom-modal-content {
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: bold;
    font-size: 130%;
  }
}
.btn:hover,
.btn:active,
.btn:focus {
  color: unset;
  background-color: unset !important;
  text-decoration: none !important;
  box-shadow: none !important;
}
</style>

<template>
  <div class="form-container">
    <loader v-if="loading"></loader>
    <!-- PREVIEW -->
    <div class="image-preview-container pr-5">
      <div class="no-image" v-if="!previewImgURL">
        <font-awesome-icon icon="file-image"></font-awesome-icon>
      </div>

      <div
        v-else
        class="image-preview bg-img"
        :style="`background-image: url('${previewImgURL}');`"
      ></div>
    </div>
    <div class="form-container-inner">
      <!-- HEADER -->
      <div class="tabs-header-container mb-5">
        <div
          class="tabs-header-element"
          :class="
            formTabIndex === 0 ? 'current' : formTabIndex > 0 ? 'valid' : ''
          "
        >
          <div class="check-icon" v-if="formTabIndex > 0">
            <font-awesome-icon icon="check-circle"></font-awesome-icon>
          </div>
          <div class="icon">
            <font-awesome-icon icon="image"></font-awesome-icon>
          </div>
          <div class="title">Dibujo</div>
        </div>
        <div
          class="tabs-header-separator"
          :class="formTabIndex > 0 ? 'valid' : ''"
        >
          <font-awesome-icon icon="arrow-right"></font-awesome-icon>
        </div>
        <div
          class="tabs-header-element"
          :class="
            formTabIndex === 1 ? 'current' : formTabIndex > 1 ? 'valid' : ''
          "
        >
          <div class="check-icon" v-if="formTabIndex > 1">
            <font-awesome-icon icon="check-circle"></font-awesome-icon>
          </div>
          <div class="icon">
            <font-awesome-icon icon="child"></font-awesome-icon>
          </div>
          <div class="title">Participante</div>
        </div>
        <div
          class="tabs-header-separator"
          :class="formTabIndex > 1 ? 'valid' : ''"
        >
          <font-awesome-icon icon="arrow-right"></font-awesome-icon>
        </div>
        <div
          class="tabs-header-element"
          :class="
            formTabIndex === 2 ? 'current' : formTabIndex > 2 ? 'valid' : ''
          "
        >
          <div class="check-icon" v-if="formTabIndex > 2">
            <font-awesome-icon icon="check-circle"></font-awesome-icon>
          </div>
          <div class="icon">
            <font-awesome-icon icon="id-card"></font-awesome-icon>
          </div>
          <div class="title">Tutor</div>
        </div>
        <div
          class="tabs-header-separator"
          :class="formTabIndex > 2 ? 'valid' : ''"
        >
          <font-awesome-icon icon="arrow-right"></font-awesome-icon>
        </div>
        <div
          class="tabs-header-element"
          :class="
            formTabIndex === 3 ? 'current' : formTabIndex > 3 ? 'valid' : ''
          "
        >
          <div class="check-icon" v-if="formTabIndex > 3">
            <font-awesome-icon icon="check-circle"></font-awesome-icon>
          </div>
          <div class="icon">
            <font-awesome-icon icon="file-signature"></font-awesome-icon>
          </div>
          <div class="title">Confirmar</div>
        </div>
      </div>

      <!-- TABS -->
      <b-tabs v-model="formTabIndex" no-key-nav nav-class="d-none">
        <!-- TAB 1 DRAWING -->
        <b-tab title-link-class="">
          <template #title></template>
          <div class="form-content">
            <b-form-group
              id="upload_file_group"
              label="Imagen de la obra"
              label-for="upload_file"
              valid-feedback="¡Listo!"
              :invalid-feedback="
                  !$v.form.tab1.file.required
                  ? 'Elige una imagen.'
                  :!$v.form.tab1.file.minImgDimensions
                  ? 'Las dimensiones mínimas son 800x600 pixeles'
                  : !$v.form.tab1.file.maxFilesize
                  ? 'La imágen debe pesar menos de 5MB.'
                  : !$v.form.tab1.file.fileExtension
                  ? 'La imagen debe ser jpg, jpeg ó png.'
                  : ''
              "
              :state="!$v.form.tab1.file.$invalid"
            >
              <b-form-file
                id="upload_file"
                v-model="form.tab1.file"
                :state="!$v.form.tab1.file.$invalid"
                placeholder="Haz click para elegir una imagen..."
                accept="image/png, image/jpeg"
                no-drop
                @change="onImageChange"
              ></b-form-file>
            </b-form-group>

            <b-form-group
              id="upload_title_group"
              label="Título de la obra"
              label-for="upload_title"
              valid-feedback="¡Listo!"
              invalid-feedback="Escribe el título de la obra."
              :state="!$v.form.tab1.title.$invalid"
            >
              <b-form-input
                id="upload_title"
                v-model="form.tab1.title"
                :state="!$v.form.tab1.title.$invalid"
                placeholder="Escribe aquí el título de la obra..."
                size="lg"
                trim
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="upload_description_group"
              description="Una buena reflexión es muy importante."
              label="Reflexión"
              label-for="upload_description"
              valid-feedback="¡Listo!"
              invalid-feedback="Escribe al menos 50 caracteres."
              :state="!$v.form.tab1.description.$invalid"
            >
              <b-form-textarea
                id="upload_description"
                v-model="form.tab1.description"
                placeholder="Escribe aquí la descripción de la obra..."
                rows="3"
                size="lg"
                :state="!$v.form.tab1.description.$invalid"
              ></b-form-textarea>
            </b-form-group>
          </div>

          <div class="form-actions-container">
            <div class="form-action mr-2"></div>
            <div class="form-action ml-2">
              <b-button
                squared
                variant="outline-success"
                class="flex-grow-shrink-0"
                style="font-size: 100%"
                :disabled="$v.form.tab1.$invalid"
                @click="formTabIndex = 1"
                :style="`color:${$options.configs.buttonColor};border-color:${$options.configs.buttonColor}`"
              >
                <div class="d-flex flex-column">
                  <div class="pt-2">
                    <strong>SIGUIENTE</strong>
                  </div>
                  <div style="font-size: 200%">
                    <font-awesome-icon icon="arrow-right"></font-awesome-icon>
                  </div>
                </div>
              </b-button>
            </div>
          </div>
          <div class="form-error-container" v-if="$v.form.tab1.$invalid">
            <div class="form-error-label">
              *Corrige los campos en rojo para continuar.
            </div>
          </div>
        </b-tab>
        <!-- TAB 2  PARTICIPANT -->
        <b-tab title="Tab 2" title-link-class="">
          <template #title></template>
          <div class="form-content">
            <b-form-group
              id="p_name_group"
              label="Nombre del autor"
              label-for="p_name"
              valid-feedback="¡Listo!"
              invalid-feedback="Escribe el nombre del autor."
              :state="!$v.form.tab2.pName.$invalid"
            >
              <b-form-input
                id="p_name"
                v-model="form.tab2.pName"
                :state="!$v.form.tab2.pName.$invalid"
                placeholder="Introduce el nombre del autor..."
                size="lg"
                trim
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="p_lastname_group"
              label="Apellido del autor"
              label-for="p_lastname"
              valid-feedback="¡Listo!"
              invalid-feedback="Escribe el apellido del autor."
              :state="!$v.form.tab2.pLastname.$invalid"
            >
              <b-form-input
                id="p_lastname"
                v-model="form.tab2.pLastname"
                :state="!$v.form.tab2.pLastname.$invalid"
                placeholder="Introduce el apellido del autor..."
                size="lg"
                trim
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="p_dob_group"
              label="Fecha de nacimiento"
              label-for="p_dob"
              valid-feedback="¡Listo!"
              invalid-feedback="Elige la fecha de nacimiento."
              :state="!$v.form.tab2.dob.$invalid"
            >
              <!-- <b-form-datepicker
                id="p_dob"
                v-model="form.tab2.dob"
                :min="getMinDOB()"
                :max="new Date()"
                value-as-date
                :state="!$v.form.tab2.dob.$invalid"
                placeholder="Introduce la fecha de nacimiento del autor..."
                size="lg"
                locale="es"
                show-decade-nav
              ></b-form-datepicker> -->
              <b-form-datepicker
                id="p_dob"
                v-model="form.tab2.dob"
                :max="new Date()"
                value-as-date
                :state="!$v.form.tab2.dob.$invalid"
                placeholder="Introduce la fecha de nacimiento del autor..."
                size="lg"
                locale="es"
                show-decade-nav
              ></b-form-datepicker>
            </b-form-group>

            <b-form-group
              id="p_tel_group"
              label="Teléfono"
              label-for="p_tel"
              description="Opcional, para que podamos avisar si gana."
              :state="form.tab2.phone.length > 0 ? true : null"
            >
              <b-form-input
                id="p_tel"
                v-model="form.tab2.phone"
                :state="form.tab2.phone.length > 0 ? true : null"
                placeholder="+12 34 56789101"
                size="lg"
                trim
                :formatter="(input) => input.replaceAll(/[^\d\s\(\)\-\+]/g, '')"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="p_email_group"
              label="Correo"
              label-for="p_email"
              description="Opcional, para que podamos avisar si gana."
              :state="
                $v.form.tab2.email.$invalid
                  ? false
                  : form.tab2.email.length > 0
                  ? true
                  : null
              "
            >
              <b-form-input
                id="p_email"
                v-model="form.tab2.email"
                :state="
                  $v.form.tab2.email.$invalid
                    ? false
                    : form.tab2.email.length > 0
                    ? true
                    : null
                "
                placeholder="Introduce el correo del autor..."
                size="lg"
                trim
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="p_postal_group"
              label="Código Postal"
              label-for="p_postal"
              :state="!$v.form.tab2.postal.$invalid"
            >
              <b-form-input
                id="p_postal"
                v-model="form.tab2.postal"
                :state="!$v.form.tab2.postal.$invalid"
                size="lg"
                trim
                :formatter="(input) => input.replaceAll(/[^\d]/g, '')"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="p_city_group"
              label="Ciudad"
              label-for="p_city"
              :state="!$v.form.tab2.city.$invalid"
            >
              <b-form-input
                id="p_city"
                v-model="form.tab2.city"
                :state="!$v.form.tab2.city.$invalid"
                size="lg"
                trim
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="p_country_group"
              label="País"
              label-for="p_country"
              :state="!$v.form.tab2.country.$invalid"
            >
              <b-form-input
                id="p_country"
                v-model="form.tab2.country"
                :state="!$v.form.tab2.country.$invalid"
                size="lg"
                trim
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="form-actions-container">
            <div class="form-action mr-2">
              <b-button
                squared
                variant="outline-success"
                class="flex-grow-shrink-0"
                style="font-size: 100%"
                @click="formTabIndex = 0"
                :style="`color:${$options.configs.buttonColor};border-color:${$options.configs.buttonColor}`"
              >
                <div class="d-flex flex-column">
                  <div class="pt-2">
                    <strong>REGRESAR</strong>
                  </div>
                  <div style="font-size: 200%">
                    <font-awesome-icon icon="arrow-left"></font-awesome-icon>
                  </div>
                </div>
              </b-button>
            </div>
            <div class="form-action ml-2">
              <b-button
                squared
                variant="outline-success"
                class="flex-grow-shrink-0"
                style="font-size: 100%"
                :disabled="$v.form.tab2.$invalid"
                @click="formTabIndex = 2"
                :style="`color:${$options.configs.buttonColor};border-color:${$options.configs.buttonColor}`"
              >
                <div class="d-flex flex-column">
                  <div class="pt-2">
                    <strong>SIGUIENTE</strong>
                  </div>
                  <div style="font-size: 200%">
                    <font-awesome-icon icon="arrow-right"></font-awesome-icon>
                  </div>
                </div>
              </b-button>
            </div>
          </div>
          <div class="form-error-container" v-if="$v.form.tab2.$invalid">
            <div class="form-error-label">
              *Corrige los campos en rojo para continuar.
            </div>
          </div>
        </b-tab>
        <!-- TAB 3 TUTOR -->
        <b-tab title="Tab 3" title-link-class="">
          <template #title></template>
          <div class="form-content">
            <b-form-group
              id="t_name_group"
              label="Nombre del tutor"
              label-for="t_name"
              valid-feedback="¡Listo!"
              invalid-feedback="Escribe el nombre del tutor."
              :state="!$v.form.tab3.tName.$invalid"
            >
              <b-form-input
                id="t_name"
                v-model="form.tab3.tName"
                :state="!$v.form.tab3.tName.$invalid"
                placeholder="Introduce el nombre del tutor..."
                size="lg"
                trim
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="t_lastname_group"
              label="Apellido del tutor"
              label-for="t_lastname"
              valid-feedback="¡Listo!"
              invalid-feedback="Escribe el apellido del tutor."
              :state="!$v.form.tab3.tLastname.$invalid"
            >
              <b-form-input
                id="t_lastname"
                v-model="form.tab3.tLastname"
                :state="!$v.form.tab3.tLastname.$invalid"
                placeholder="Introduce el apellido del tutor..."
                size="lg"
                trim
              ></b-form-input>
            </b-form-group>

            <div class="form-actions-container">
              <div class="form-action mr-2">
                <b-button
                  squared
                  variant="outline-success"
                  class="flex-grow-shrink-0"
                  style="font-size: 100%"
                  @click="formTabIndex = 1"
                  :style="`color:${$options.configs.buttonColor};border-color:${$options.configs.buttonColor}`"
                >
                  <div class="d-flex flex-column">
                    <div class="pt-2">
                      <strong>REGRESAR</strong>
                    </div>
                    <div style="font-size: 200%">
                      <font-awesome-icon icon="arrow-left"></font-awesome-icon>
                    </div>
                  </div>
                </b-button>
              </div>
              <div class="form-action ml-2">
                <b-button
                  squared
                  variant="outline-success"
                  class="flex-grow-shrink-0"
                  style="font-size: 100%"
                  :disabled="$v.form.tab3.$invalid"
                  @click="formTabIndex = 3"
                  :style="`color:${$options.configs.buttonColor};border-color:${$options.configs.buttonColor}`"
                >
                  <div class="d-flex flex-column">
                    <div class="pt-2">
                      <strong>SIGUIENTE</strong>
                    </div>
                    <div style="font-size: 200%">
                      <font-awesome-icon icon="arrow-right"></font-awesome-icon>
                    </div>
                  </div>
                </b-button>
              </div>
            </div>
            <div class="form-error-container" v-if="$v.form.tab3.$invalid">
              <div class="form-error-label">
                *Corrige los campos en rojo para continuar.
              </div>
            </div>
          </div>
        </b-tab>

        <!-- TAB 4 CONFIRM -->
        <b-tab title="Tab 4" title-link-class="">
          <template #title></template>
          <div class="form-content">
            <div class="table-title">Verifica la información proporcionada</div>
            <table class="confirmation-table">
              <tr>
                <td class="table-section-title" colspan="2">Dibujo</td>
              </tr>
              <tr>
                <td>Imagen:</td>
                <td>{{ form.tab1.file ? form.tab1.file.name : "" }}</td>
              </tr>
              <tr>
                <td>Título:</td>
                <td>{{ form.tab1.title }}</td>
              </tr>
              <tr>
                <td>Reflexión:</td>
                <td>{{ form.tab1.description }}</td>
              </tr>

              <tr>
                <td class="table-section-title" colspan="2">Participante</td>
              </tr>
              <tr>
                <td>Nombre Completo:</td>
                <td>{{ form.tab2.pName }} {{ form.tab2.pLastname }}</td>
              </tr>
              <tr>
                <td>Fecha de Nacimiento:</td>
                <td>
                  {{
                    form.tab2.dob
                      ? form.tab2.dob.toLocaleDateString("es-ES", {
                          weekday: "short",
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        })
                      : ""
                  }}
                </td>
              </tr>
              <tr>
                <td>Teléfono:</td>
                <td>{{ form.tab2.phone }}</td>
              </tr>
              <tr>
                <td>Email:</td>
                <td>{{ form.tab2.email }}</td>
              </tr>
              <tr>
                <td>Código Postal:</td>
                <td>{{ form.tab2.postal }}</td>
              </tr>
              <tr>
                <td>Ciudad:</td>
                <td>{{ form.tab2.city }}</td>
              </tr>
              <tr>
                <td>País:</td>
                <td>{{ form.tab2.country }}</td>
              </tr>

              <tr>
                <td class="table-section-title" colspan="2">Tutor</td>
              </tr>
              <tr>
                <td>Nombre Completo:</td>
                <td>{{ form.tab3.tName }} {{ form.tab3.tLastname }}</td>
              </tr>
            </table>

            <b-form-checkbox
              id="checkbox_verified"
              v-model="form.tab4.verified"
              name="checkbox_verified"
              :state="!$v.form.tab4.verified.$invalid"
              class="mb-3"
            >
              Revisé que todos los datos son correctos y estoy consciente que
              proveer datos incorrectos puede resultar en la descalificación de
              este dibujo, como establecido en los
              <b-link v-b-modal.modal-terms>Términos y Condiciones</b-link>.
            </b-form-checkbox>
            <b-form-checkbox
              id="checkbox_color"
              v-model="form.tab4.colorCheck"
              name="checkbox_color"
              :state="!$v.form.tab4.colorCheck.$invalid"
              class="mb-3"
            >
              Entiendo y estoy de acuerdo que si el dibujo no cumple con los lineamientos de colores, este podrá ser modificado para su correcta impresión.
            </b-form-checkbox>
            <b-form-checkbox
              id="checkbox_terms"
              v-model="form.tab4.terms"
              name="checkbox_terms"
              :state="!$v.form.tab4.terms.$invalid"
            >
              He leído y estoy de acuerdo con los
              <b-link v-b-modal.modal-terms>Términos y Condiciones</b-link> al
              igual que las
              <b-link v-b-modal.modal-privacy>Políticas de Privacidad</b-link>.
            </b-form-checkbox>

            <div class="form-actions-container">
              <div class="form-action mr-2">
                <b-button
                  squared
                  variant="outline-success"
                  class="flex-grow-shrink-0"
                  style="font-size: 100%"
                  @click="formTabIndex = 2"
                  :style="`color:${$options.configs.buttonColor};border-color:${$options.configs.buttonColor}`"
                >
                  <div class="d-flex flex-column">
                    <div class="pt-2">
                      <strong>REGRESAR</strong>
                    </div>
                    <div style="font-size: 200%">
                      <font-awesome-icon icon="arrow-left"></font-awesome-icon>
                    </div>
                  </div>
                </b-button>
              </div>
              <div class="form-action ml-2">
                <b-button
                  squared
                  variant="outline-success"
                  class="flex-grow-shrink-0"
                  style="font-size: 100%"
                  :disabled="$v.form.tab4.$invalid || loading"
                  @click="onSubmit()"
                  :style="`color:${$options.configs.buttonColor};border-color:${$options.configs.buttonColor}`"
                >
                  <div class="d-flex flex-column">
                    <div class="pt-2">
                      <strong>PARTICIPAR</strong>
                    </div>
                    <div style="font-size: 200%">
                      <font-awesome-icon
                        icon="check-circle"
                      ></font-awesome-icon>
                    </div>
                  </div>
                </b-button>
              </div>
            </div>

            <div class="form-error-container" v-if="$v.form.tab4.$invalid">
              <div class="form-error-label">
                *Completa los campos faltantes para continuar.
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>

      <!-- MODALS -->
      <div class="modals-container">
        <b-modal
          id="modal-terms"
          size="lg"
          centered
          header-class="custom-modal-header"
          ok-title="Cerrar"
          scrollable
        >
          <template #modal-header="{ close }">
            <h1>Términos y Condiciones</h1>
            <b-button-close @click="close()"></b-button-close>
          </template>
          <div
            class="custom-modal-content"
            v-html="$options.configs.terms"
          ></div>
          <template #modal-footer="{ ok }">
            <b-button size="sm" variant="success" @click="ok()"
              >Cerrar</b-button
            >
          </template>
        </b-modal>
        <b-modal
          id="modal-privacy"
          size="lg"
          centered
          header-class="custom-modal-header"
          ok-title="Cerrar"
          scrollable
        >
          <template #modal-header="{ close }">
            <h1>Política de Privacidad</h1>
            <b-button-close @click="close()"></b-button-close>
          </template>
          <div
            class="custom-modal-content"
            v-html="$options.configs.privacy"
          ></div>
          <template #modal-footer="{ ok }">
            <b-button size="sm" variant="success" @click="ok()"
              >Cerrar</b-button
            >
          </template>
        </b-modal>
        <b-modal
          id="modal-success"
          size="sm"
          centered
          header-class="custom-modal-header"
          ok-title="Cerrar"
        >
          <template #modal-header="{ close }">
            <h1>¡Éxito!</h1>
            <b-button-close @click="close()"></b-button-close>
          </template>
          <div class="custom-modal-content">
            <div class="d-flex justify-content-center align-items-center pb-5" style="font-size:5rem;color:rgb(4,155,4);">
              <div><font-awesome-icon icon="check-circle"></font-awesome-icon></div>
            </div>
            <p>Tu participacion ha sido registrada.</p>
            <p>
              Después de haber sido aprobada por un moderador, el dibujo será
              publicado en la sección de Dibujos.
            </p>
          </div>
          <template #modal-footer="{  }">
            <b-button size="sm" variant="success" @click="goToParticipation(participationId)"
              >Cerrar</b-button
            >
          </template>
        </b-modal>
        <b-modal
          id="modal-error"
          size="sm"
          centered
          header-class="custom-modal-header"
          ok-title="Cerrar"
        >
          <template #modal-header="{ close }">
            <h1>Error</h1>
            <b-button-close @click="close()"></b-button-close>
          </template>
          <div class="custom-modal-content">
            <div class="d-flex justify-content-center align-items-center pb-5" style="font-size:5rem;color: #dc3545;">
              <div><font-awesome-icon icon="exclamation-circle"></font-awesome-icon></div>
            </div>
            <p>Hubo un problema con tu solicitud:</p>
            <ul>
              <li v-for="(error, name, index) in errors" :key="index">{{ name }}:{{ error }}</li>
            </ul>
          </div>
          <template #modal-footer="{ ok }">
            <b-button size="sm" variant="success" @click="ok()"
              >Cerrar</b-button
            >
          </template>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength, email, helpers } from "vuelidate/lib/validators";
import axios from "axios";
import Loader from "../loaders/Loader.vue"

// const { withAsync } = helpers

const maxFilesize = (value) =>
  value ? (value.size > 5000000 ? false : true) : true;

const minImgDimensions = async (file) => {
  if (file === null) {
    return false
  }
  // console.log("FILE",file)
  let b64 = await new Promise((resolve) => {
    let fileReader = new FileReader();
    fileReader.onload = (e) => resolve(fileReader.result);
    fileReader.onerror = (e) => resolve(false);
    fileReader.readAsDataURL(file);
  });
  if (b64 === false) {
    console.log("b64", b64)
    return false
  }

  let img = await new Promise((resolve) => {
    let img = new Image();
    img.onload = () => resolve(img);
    img.onerror = () => resolve(false);
    img.src = b64;
  })
  if (img === false) {
    console.log("img", img)
    return false
  }
  // console.log("DIMS", img.width, img.height)
  if (img.width < 800 || img.height < 600) {
    return false
  }  
  return true
}

const allowedExtensions = ["jpg", "jpeg", "png"];
const fileExtension = (value) => {
  if (!value) {
    return true;
  }
  let ext = value.name.split(".");
  ext = ext[ext.length - 1];
  return allowedExtensions.includes(ext);
};

export default {
  name: "ParticipationForm",
  user: window.configs.user,
  configs: window.configs.uploadDrawing.form,
  components: { Loader },
  data() {
    return {
      form: {
        csrfToken: new String(this.$options.user.uploadCsrf),
        tab1: {
          title: "",
          description: "",
          file: null,
        },
        tab2: {
          pName: "",
          pLastname: "",
          dob: null,
          phone: "",
          email: "",
          postal: "",
          city: "",
          country: "",
        },
        tab3: {
          tName: "",
          tLastname: "",
        },
        tab4: {
          verified: false,
          terms: false,
        },
      },
      errors: [],
      previewImgURL: "",
      formTabIndex: 0,
      loading: false,
      submitError: false,
      participationId: null
    };
  },
  validations: {
    form: {
      tab1: {
        file: {
          required,
          maxFilesize,
          fileExtension,
          minImgDimensions: minImgDimensions
        },
        title: {
          required,
        },
        description: {
          required,
          minLength: minLength(50),
        },
      },
      tab2: {
        pName: {
          required,
        },
        pLastname: {
          required,
        },
        dob: {
          required,
        },
        phone: {
          minLength: minLength(1),
        },
        email: {
          email,
        },
        postal: {
          required,
        },
        city: {
          required,
        },
        country: {
          required,
        },
      },
      tab3: {
        tName: {
          required,
        },
        tLastname: {
          required,
        },
      },
      tab4: {
        verified: {
          checked: (value) => value === true,
        },
        terms: {
          checked: (value) => value === true,
        },
        colorCheck:  {
          checked: (value) => value === true,
        }
      },
    },
  },
  methods: {
    getMinDOB() {
      let thisYear = new Date();
      thisYear = new Date(thisYear.getFullYear(), 0, 1);
      let minDate = new Date(thisYear);
      minDate.setFullYear(thisYear.getFullYear() - 17);
      // console.log(minDate);
      return minDate;
    },
    onImageChange(e) {
      if (e.target.files.length > 0) {
        this.file = e.target.files[0];
        //   console.log(this.file);
        this.previewImgURL = URL.createObjectURL(this.file);
      } else {
        this.file = null;
        this.previewImgURL = "";
      }
    },
    onSubmit(e) {
      this.loading = true;
      let fd = new FormData();
      fd.append("name", this.form.tab2.pName);
      fd.append("lastname", this.form.tab2.pLastname);
      fd.append("description", this.form.tab1.description);
      fd.append("title", this.form.tab1.title);
      fd.append("dob", this.form.tab2.dob.toISOString().split("T")[0]);
      fd.append("phone", this.form.tab2.phone);
      fd.append("email", this.form.tab2.email);
      fd.append("postal", this.form.tab2.postal);
      fd.append("city", this.form.tab2.city);
      fd.append("country", this.form.tab2.country);
      fd.append("tutor_name", this.form.tab3.tName);
      fd.append("tutor_lastname", this.form.tab3.tLastname);
      fd.append("image_file", this.form.tab1.file);
      fd.append("_token", this.form.csrfToken);
      axios
        .post("/participation", fd)
        .then((response) => {
          // console.log(response);
          this.participationId = response.data.id;
          this.submitError = false;
          this.$bvModal.show("modal-success");
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
          this.errors = error.response.data.errors;
          this.submitError = true;
          this.loading = false;
          this.$bvModal.show("modal-error");
        });
    },
    goToParticipation(id) {
      this.$router.push({ name: 'my_drawings', params: { id: this.participationId }})
    }
  },
};
</script>
