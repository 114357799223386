<style lang="scss" scoped>
.hero-title {
  .hero-text {
    font-size: 700%;
    font-family: "MoonFlower";
    font-weight: bold;
    letter-spacing: 0.1em;
    text-align: center;
  }
}
.pe-none {
  pointer-events: none;
}
.title-outer-container {
  margin-bottom: 5em;
}
.content {
  margin: 0px 10rem 10rem;
  @media only screen and (max-width: 1200px) {
    margin: 0px 5rem 8rem;
  }
  @media only screen and (max-width: 767px) {
    margin: 0px 1rem 6rem;
  }
}
.drawings-carrousel {
  position: relative;
  .carrousel-page {
    z-index: 1;
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 20em 20em;
    column-gap: 2em;
    row-gap: 2em;
    @media only screen and (max-width: 1200px) {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 20em 20em 20em 20em;
    }
    @media only screen and (max-width: 767px) {
      display: flex;
      flex-direction: column;
    }
    .page-item {
      cursor: pointer;
    }
  }
  .carrousel-controls {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    @media only screen and (max-width: 767px) {
      position: unset;
      display: flex;
      justify-content: center;
      padding-top: 1em;
    }
    .carrousel-control {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      right: 0;
      top: 0;
      bottom: 0;
      font-size: 5em;
      color: #41403b;
      transform: translate(140%, 0px);
      filter: drop-shadow(3px 3px 3px rgb(194, 194, 194));
      transition: transform 0.2s ease;
      // transition: drop-shadow 0.2s ease;
      @media only screen and (max-width: 767px) {
        position: unset;
      }
      &.disabled {
        opacity: 0.2;
      }
      &:hover {
        transform: translate(140%, 0px) scale(1.1);
        // filter: drop-shadow(3px 3px 5px rgb(152, 152, 152));
        cursor: pointer;
      }
      &:active {
        transform: translate(140%, 0px) scale(0.9);
      }
      &.left {
        right: unset;
        left: 0;
        transform: translate(-140%, 0px) rotate(180deg);
        filter: drop-shadow(-3px -3px 3px rgb(194, 194, 194));
        &:hover {
          transform: translate(-140%, 0px) rotate(180deg) scale(1.1);
          // filter: drop-shadow(3px 3px 5px rgb(152, 152, 152));
          cursor: pointer;
        }
        &:active {
          transform: translate(-140%, 0px) rotate(180deg) scale(0.9);
        }
      }
    }
  }
}
.searchbar-container {
  margin-bottom: 2rem;
  display: flex;
  @media only screen and (max-width: 1200px) {
    flex-direction: column;
  }
  .orderby-container {
    width: 40%;
    @media only screen and (max-width: 1200px) {
      width: unset;
    }
  }
  .filter-container {
    width: 35%;
    margin-right: 0.5em;
    @media only screen and (max-width: 1200px) {
      width: unset;
    }
  }
}
</style>

<template>
  <div class="page-container">
    <div class="title-outer-container">
      <page-header
        :title="$options.configs.header.title"
        header-class="dibujos"
        subtitle=""
        :bg-url="assets.headerBgUrl"
        :bg-position="$options.configs.header.bgPosition"
        :bg-size="$options.configs.header.bgSize"
        :title-color="$options.configs.header.titleColor"
      />
    </div>
    <div class="content dibujos">
      <div class="searchbar-container">
        <search-input
          @clear="clearSearch()"
          @change="onSearchChange"
          class="mr-2"
        ></search-input>
        <category-filter-input
          @change="onCategoryFilterChange"
          class="filter-container"
          :contestId="contestFilterId"
        />
        <contest-filter-input
          @change="onContestFilterChange"
          class="filter-container"
        />
        <order-by-input
          :orderByOptions="orderByOptions"
          @change="onOrderChange"
          @changeAsc="onOrderAscChange"
          class="orderby-container"
        ></order-by-input>
      </div>
      <div class="drawings-carrousel" v-if="drawings.length > 0 || page > 1">
        <loader v-if="loading"></loader>
        <div class="carrousel-page">
          <div
            class="page-item"
            v-for="(drawing, index) in drawings"
            :key="drawing.id"
          >
            <drawing-list-item
              :title="drawing.title"
              :src="drawing.img_path"
              :publicVote="drawing.public_vote"
              :createdByMe="drawing.created_by_me"
              :index="index"
              @click="onClickDrawing(drawing, index)"
            ></drawing-list-item>
          </div>
        </div>
        <div class="carrousel-controls">
          <div
            class="carrousel-control right"
            :class="page < totalPages && !loading ? '' : 'pe-none disabled'"
            @click="nextPage()"
          >
            <font-awesome-icon icon="angle-right"></font-awesome-icon>
          </div>
          <div
            class="carrousel-control left"
            :class="page > 1 && !loading ? '' : 'pe-none disabled'"
            @click="previousPage()"
          >
            <font-awesome-icon class="" icon="angle-right"></font-awesome-icon>
          </div>
        </div>
      </div>
      <div class="text-center" style="font-size:150%" v-else-if="search !== ''">
        No hay dibujos con tu búsqueda.
      </div>
      <div class="text-center" style="font-size:150%" v-else>
        Aún no hay dibujos.
        <router-link
          :to="{ name: 'uploadDrawing' }"
          v-if="$options.user && $options.user.isLogged"
          >Sube tu Dibujo</router-link
        >
      </div>
    </div>
    <div>
      <image-modal
        id="drawings-modal"
        :drawing="modalDrawing"
        @publicVoteChange="onPublicVoteChange"
        @juryVoteChange="onJuryVoteChange"
      />
    </div>
    <page-footer />
  </div>
</template>

<script>
import PageHeader from "../components/PageHeader";
import PageFooter from "../components/PageFooter.vue";
import axios from "axios";
import Loader from "../components/loaders/Loader.vue";
import DrawingListItem from "../components/drawings/DrawingListItem.vue";
import ImageModal from "../components/modals/ImageModal.vue";
import SearchInput from "../components/forms/SearchInput.vue";
import OrderByInput from "../components/forms/OrderByInput.vue";
import CategoryFilterInput from "../components/forms/CategoryFilterInput.vue";
import ContestFilterInput from "../components/forms/ContestFilterInput.vue";

export default {
  components: {
    PageHeader,
    PageFooter,
    Loader,
    DrawingListItem,
    ImageModal,
    SearchInput,
    OrderByInput,
    CategoryFilterInput,
    ContestFilterInput,
  },
  name: "Drawings",
  configs: window.configs.drawings,
  user: window.configs.user,
  data() {
    return {
      assets: {
        headerBgUrl: "",
      },
      drawings: [],
      paginationData: {
        page: 1,
        perPage: 8,
        totalItems: 0,
      },
      modalDrawing: {},
      page: 1,
      loading: false,
      search: "",
      categoryFilterId: null,
      contestFilterId: null,
      orderBy: "created",
      orderAsc: false,
      orderByOptions: [
        { value: "created", text: "Fecha" },
        {
          value: "my_votes",
          text: "Mis Votos",
          disabled: !this.$options.user.isLogged,
        },
      ],
    };
  },
  mounted() {
    this.getAssets();
  },
  methods: {
    getAssets() {
      this.assets = window.assets.drawings;
      this.getDrawings();
    },
    getDrawings(onFail = () => {}) {
      this.loading = true;
      let query = `/api/drawings?page=${this.page}&order_by=${
        this.orderBy
      }&order=${this.orderAsc ? "ASC" : "DESC"}`;
      if (this.search !== "") {
        query += "&search=" + this.search;
      }
      if (this.categoryFilterId) {
        query += "&category_id=" + this.categoryFilterId;
      }
      if (this.contestFilterId) {
        query += "&contest_id=" + this.contestFilterId;
      }
      axios
        .get(query)
        .then((response) => {
          // console.log(response);
          this.drawings = response.data.data;
          this.paginationData.page = response.data.meta.page;
          this.paginationData.perPage = response.data.meta.per_page;
          this.paginationData.totalItems = response.data.meta.total_items;
        })
        .catch((error) => {
          // handle error
          console.log(error);
          onFail(error);
        })
        .then(() => {
          // always executed
          this.loading = false;
        });
    },
    onClickDrawing(drawing, index) {
      this.modalDrawing = { ...drawing, index: index };
      this.$bvModal.show("drawings-modal");
      // console.log(drawing);
    },
    nextPage() {
      if (this.page <= this.totalPages) {
        this.page += 1;
        this.getDrawings(() => {
          this.page -= 1;
        });
      }
    },
    previousPage() {
      if (this.page > 1) {
        this.page -= 1;
        this.getDrawings(() => {
          this.page += 1;
        });
      }
    },
    clearSearch() {
      this.search = "";
      this.getDrawings();
    },
    onSearchChange(val) {
      this.search = val;
      this.getDrawings();
    },
    onCategoryFilterChange(val) {
      this.categoryFilterId = val;
      this.getDrawings();
    },
    onContestFilterChange(val) {
      this.contestFilterId = val;
      this.categoryFilterId = null;
      this.getDrawings();
    },
    onOrderChange(val) {
      this.orderBy = val;
      this.getDrawings();
    },
    onOrderAscChange(val) {
      this.orderAsc = val;
      this.getDrawings();
    },
    onPublicVoteChange(drawing) {
      this.$set(
        this.drawings[drawing.index],
        "public_vote",
        drawing.public_vote
      );
    },
    onJuryVoteChange(drawing) {
      this.$set(
        this.drawings[drawing.index],
        "jury_votes",
        drawing.jury_votes
      );
      // console.log(drawing);
    },
  },
  computed: {
    totalPages: function() {
      return Math.ceil(
        this.paginationData.totalItems / this.paginationData.perPage
      );
    },
  },
};
</script>
