<style lang="scss" scoped></style>

<template>
  <div class="more-info-page">
    <div class="inner-container" >
      <div
        class="title"
        v-html="title"
        :style="{'color': titleColor }"
      ></div>
      <div class="text" v-html="text"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BasisInfo",
  props: {
    title: String,
    titleColor: String,
    text: String,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
