<style lang="scss" scoped>
$avatar-size: 5em;
.avatar-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  .avatar-item {
    margin: 0.5em;
    padding: 0.5em;
    border-radius: 10%;
    height: $avatar-size;
    width: $avatar-size;
    cursor: pointer;
    &.current {
      cursor: unset;
      background-color: #d8d8d8;
    }
    &.selected {
      background-color: #aeffa3;
    }
    &:hover {
      background-color: #e6e4e4;
      &.selected {
        background-color: #aeffa3;
      }
      &.current {
        background-color: #d8d8d8;
      }
    }
  }
}
</style>

<template>
  <b-modal id="avatar-modal" title="Elige otro avatar" scrollable 
      @ok="changeAvatar" style="position: relative">
    <loader v-if="loading" :show="true" />
    <div class="avatar-list">
      <div
        class="avatar-item"
        v-for="(avatar, index) in $options.icons.animals"
        :key="index"
        @click="select(avatar)"
        :class="[
          $options.user.avatar_filename === avatar ? 'current' : '',
          avatar === selected ? 'selected' : '',
        ]"
      >
        <div
          class="bg-img w-100 h-100"
          :style="
            'background-image:url(\'icon_collection/animals/' + avatar + '\')'
          "
        ></div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import animalIcons from "../../resources/animal_icon_list";
import Loader from '../loaders/Loader.vue';
import axios from "axios";

export default {
  components: { Loader },
  name: "AvatarModal",
  props: {
    currentAvatar: String,
  },
  user: window.configs.user,
  data() {
    return {
      selected: "",
      loading: false
    };
  },
  mounted() {},
  methods: {
    select(avatar) {
      if (this.$options.user.avatar_filename !== avatar) {
        this.selected = avatar;
      }
    },
    changeAvatar(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.loading = true;
      let fd = new FormData();
      fd.append("avatar", this.selected);
      axios
        .post("/api/user/avatar", fd)
        .then((response) => {
          // console.log(response);
          window.configs.user.avatar_filename = String(this.selected);
          this.loading = false;
          this.$bvModal.hide('avatar-modal');
          location.reload();
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
          this.loading = false;
        });
    }
  },
  icons: {
    animals: animalIcons,
  },
};
</script>
