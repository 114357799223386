<style lang="scss" scoped>
.hero-title {
  .hero-text {
    font-size: 700%;
    font-family: "MoonFlower";
    font-weight: bold;
    letter-spacing: 0.1em;
    text-align: center;
  }
}
</style>

<template>
  <div class="page-container">
    <page-header
      :title="$options.configs.header.title"
      header-class="subir"
      subtitle=""
      :bg-url="assets.headerBgUrl"
      :bg-position="$options.configs.header.bgPosition"
      :bg-size="$options.configs.header.bgSize"
      :title-color="$options.configs.header.titleColor"
    />
    <div class="content subir">
      <div class="content-outer">
        <div class="content-inner">
          <participation-form></participation-form>
        </div>
      </div>
    </div>
    <page-footer />
  </div>
</template>

<script>
import PageHeader from "../components/PageHeader";
import PageFooter from "../components/PageFooter.vue";
import JuryContainer from "../components/jury/JuryContainer.vue";
import ParticipationForm from "../components/upload/ParticipationForm";

export default {
  components: { PageHeader, PageFooter, JuryContainer, ParticipationForm },
  name: "UploadDrawing",
  configs: window.configs.uploadDrawing,
  data() {
    return {
      assets: {
        headerBgUrl: "",
      },
    };
  },
  mounted() {
    this.getAssets();
  },
  methods: {
    getAssets() {
      this.assets = window.assets.uploadDrawing;
    },
  },
};
</script>
