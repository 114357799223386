var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      staticStyle: { position: "relative" },
      attrs: { id: "avatar-modal", title: "Elige otro avatar", scrollable: "" },
      on: { ok: _vm.changeAvatar }
    },
    [
      _vm.loading ? _c("loader", { attrs: { show: true } }) : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "avatar-list" },
        _vm._l(_vm.$options.icons.animals, function(avatar, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "avatar-item",
              class: [
                _vm.$options.user.avatar_filename === avatar ? "current" : "",
                avatar === _vm.selected ? "selected" : ""
              ],
              on: {
                click: function($event) {
                  return _vm.select(avatar)
                }
              }
            },
            [
              _c("div", {
                staticClass: "bg-img w-100 h-100",
                style:
                  "background-image:url('icon_collection/animals/" +
                  avatar +
                  "')"
              })
            ]
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }