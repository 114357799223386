import Vue from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faVoteYea,
  faCompass,
  faFileAlt,
  faUsers,
  faPersonBooth,
  faArrowRight,
  faImage,
  faFileImage,
  faIdCard,
  faChild,
  faCheckCircle,
  faArrowLeft,
  faFileSignature,
  faExclamationCircle,
  faTimesCircle,
  faAngleRight,
  faSortAmountUp,
  faSortAmountDownAlt,
  faSyncAlt
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faVoteYea,
  faCompass,
  faFileAlt,
  faUsers,
  faPersonBooth,
  faArrowRight,
  faArrowLeft,
  faImage,
  faFileImage,
  faIdCard,
  faChild,
  faCheckCircle,
  faFileSignature,
  faExclamationCircle,
  faTimesCircle,
  faAngleRight,
  faSortAmountUp,
  faSortAmountDownAlt,
  faSyncAlt
);

Vue.component("font-awesome-icon", FontAwesomeIcon);
