<template>
  <b-input-group>
      <b-button slot="prepend" @click="value = null" variant="outline-secondary" :disabled="value === null || value === undefined">Cancelar</b-button>
      <b-button slot="prepend" @click="value = 0" :variant="value === 0 ? 'danger' : 'outline-danger'" v-if="showDisqualified">{{value === 0 ? 'Descalificado':'Descalificar'}}</b-button>
    <b-form-rating v-model="value" color="#ff8800" no-border :size="size"></b-form-rating>
  </b-input-group>
</template>

<script>
export default {
  name: "RatingInput",
  props: ['showDisqualified', 'size', 'initValue'],
  data() {
    return {
      value: this.initValue,
    };
  },
  methods: {
    clear() {
      this.value = null;
      this.$emit("change", this.value);
    },
  },
  watch: {
    value: function(newVal, oldVal) {
      this.$emit("change", newVal);
    },
  },
};
</script>

<style></style>
