<style lang="scss" scoped>
.overflow-hidden {
  overflow: hidden;
}
.list-item-container {
  display: flex;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  min-height: 25em;
  margin-bottom: 2rem;
  @media only screen and (max-width: 1100px) {
    flex-direction: column;
  }
  .img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  .img-container {
    flex: 0 0 auto;
    position: relative;
    width: 40%;
    height: 100%;
    @media only screen and (max-width: 1100px) {
      width: 100%;
    }
  }
  .info-container {
    flex: 1 1 auto;
    padding: 1em;
    font-size: 150%;
    .data-container {
      margin-bottom: 0.5em;
      .title {
        font-size: 60%;
        color: #5c5b5b;
      }
      // .data {
      // }
      .status-pill {
        padding: .5em .2em;
        border-radius: 50%;
      }
    }
  }
}
.spinner-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .spinner {
    width: 30%;
    height: 30%;
  }
}
</style>

<template>
  <div class="list-item-container">
    <div class="img-container" @click="$emit('click')">
      <div class="spinner-container" v-if="!loaded">
        <image-spinner-icon class="spinner"></image-spinner-icon>
      </div>
      <b-img class="img" :src="src" :alt="title" @load="onLoad()"></b-img>
    </div>
    <div class="info-container">
      <div class="data-container">
        <div class="title">Título:</div>
        <div class="data">{{ title }}</div>
      </div>
      <div class="data-container">
        <div class="title">Reflexión:</div>
        <div class="data">{{ description }}</div>
      </div>
      <div class="data-container">
        <div class="title">Concurso:</div>
        <div class="data">
          {{ contest.name }}
        </div>
      </div>
      <div class="data-container">
        <div class="title">Categoría:</div>
        <div class="data">
          {{ category.name }} ({{ category.min_age }}-{{
            category.max_age
          }}años)
        </div>
      </div>
      <div class="data-container">
        <div class="title">Estado:</div>
        <div class="data">
          <b-badge pill :variant="translatedStatus.color">{{ translatedStatus.label }}</b-badge>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageSpinnerIcon from "../Icons/ImageSpinnerIcon.vue";

const STATUSES = {
  STATUS_PENDING_REVIEW: {
    label: "Pendiente de aprobación",
    color: "secondary",
  },
  STATUS_REJECTED: {
    label: "Rechazado",
    color: "danger",
  },
  STATUS_APPROVED: {
    label: "Aprobado",
    color: "info",
  },
  STATUS_DISQUALIFIED: {
    label: "Descalificado",
    color: "dark",
  },
  STATUS_WINNER: {
    label: "Ganador",
    color: "success",
  },
};

export default {
  name: "MyDrawingItem",
  components: { ImageSpinnerIcon },
  props: [
    "title",
    "src",
    "description",
    "createdAt",
    "status",
    "index",
    "category",
    "contest",
  ],
  data() {
    return {
      loaded: false,
    };
  },
  methods: {
    onLoad() {
      this.loaded = true;
    },
  },
  computed: {
    translatedStatus: function() {
      return STATUSES[this.status] ? STATUSES[this.status] : {label: "Otro", color:"white"};
    },
  },
};
</script>
