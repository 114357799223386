var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "user-sidebar d-flex flex-column",
      class: _vm.containerClass,
      on: {
        click: function($event) {
          return _vm.$emit("click")
        }
      }
    },
    [
      _c("div", { staticClass: "sidebar-container" }, [
        _c("div", { staticClass: "avatar-container" }, [
          _c("div", { staticClass: "avatar" }, [
            _c("div", {
              staticClass: "bg-img w-100 h-100",
              style: "background-image:url('" + _vm.avatarUrl + "')"
            })
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "actions-container" }, [
          _c("div", { staticClass: "username" }, [
            _vm._v(_vm._s(_vm.user.name) + " " + _vm._s(_vm.user.lastname))
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "bottom-spacer" })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }