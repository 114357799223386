<style lang="scss" scoped></style>

<template>
  <div>
    <b-modal
      id="registration-modal"
      title="Registrar"
      header-bg-variant="info"
      header-text-variant="light"
      header-class="align-items-baseline position-relative"
      hide-footer
      centered
      no-stacking
    >
      <template #modal-header="{ close }">
        <b-icon icon="key" class="mr-2" aria-hidden="true" />
        <h5>Crear Cuenta</h5>
        <b-button-close @click="close()" />
      </template>

      <template #default>
        <loader v-show="loading"></loader>
        <p class="mb-2">¡Regístrate para participar o votar!</p>
        <b-form action="/registration" @submit.stop.prevent="onSubmit">

          <b-form-group label-size="md" label="Correo" label-for="registration-email" :class="$v.form.username.$invalid ? 'mb-0' : ''">
            <b-input-group size="md">

              <b-input-group-prepend>
                <b-input-group-text>
                  <b-icon icon="person" />
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input
                id="registration-email"
                v-model="form.username"
                :state="$v.form.username.$invalid ? false : null"
                autocomplete="email"
              ></b-form-input>
            </b-input-group>
            <div class="text-muted text-right" v-if="!$v.form.username.email || !$v.form.username.required">
              Debe ser un correo válido.
            </div>
            <div class="text-muted text-right" v-if="!$v.form.username.unique && $v.form.username.email && $v.form.username.required && !fetchingUser">
              Este correo ya ha sido registrado.
            </div>
          </b-form-group>

          <b-form-group label-size="md" label="Nombre" label-for="registration-name" :class="$v.form.name.$invalid ? 'mb-0' : ''">
            <b-input-group size="md">
              <b-input-group-prepend>
                <b-input-group-text>
                  <b-icon icon="person" />
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input
                id="registration-name"
                v-model="form.name"
                :state="$v.form.name.$invalid ? false : null"
                autocomplete="given-name"
              ></b-form-input>
            </b-input-group>
            <div class="text-muted text-right" v-if="$v.form.name.$invalid">
              Requerido.
            </div>
          </b-form-group>

          <b-form-group label-size="md" label="Apellido" label-for="registration-lastname" :class="$v.form.lastname.$invalid ? 'mb-0' : ''">
            <b-input-group size="md">
              <b-input-group-prepend>
                <b-input-group-text>
                  <b-icon icon="person" />
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input
                id="registration-lastname"
                v-model="form.lastname"
                :state="$v.form.lastname.$invalid ? false : null"
                autocomplete="family-name"
              ></b-form-input>
            </b-input-group>
            <div class="text-muted text-right" v-if="$v.form.lastname.$invalid">
              Requerido.
            </div>
          </b-form-group>

          <b-form-group
            label-size="md"
            label="Contraseña"
            label-for="registration-password"
            :class="$v.form.password.$invalid ? 'mb-0' : ''"
          >
            <b-input-group size="md">
              <b-input-group-prepend>
                <b-input-group-text>
                  <b-icon icon="key" />
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input
                id="registration-password"
                type="password"
                v-model="form.password"
                :state="$v.form.password.$invalid ? false : null"
                autocomplete="new-password"
              ></b-form-input>
            </b-input-group>
            <div class="text-muted text-right" v-if="!$v.form.password.required">
              Requerida
            </div>
            <div class="text-muted text-right" v-if="!$v.form.password.minLengthValue && $v.form.password.required">
              Debe contener al menos 6 caracteres.
            </div>
            <div class="text-muted text-right" v-if="!$v.form.password.regex && $v.form.password.required && $v.form.password.minLengthValue">
              Usa al menos una mayúscula, una minúscula y un número.
            </div>
          </b-form-group>
          <b-form-group
            label-size="md"
            label="Repetir Contraseña"
            label-for="registration-password2"
            :class="$v.form.password2.$invalid ? 'mb-0' : ''"
          >
            <b-input-group size="md">
              <b-input-group-prepend>
                <b-input-group-text>
                  <b-icon icon="key" />
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input
                id="registration-password2"
                type="password"
                v-model="form.password2"
                :state="$v.form.password2.$invalid ? false : null"
                autocomplete="new-password"
              ></b-form-input>
            </b-input-group>
            <div class="text-muted text-right" v-if="!$v.form.password2.required">
              Requerida
            </div>
            <div class="text-muted text-right" v-if="!$v.form.password2.sameAs && $v.form.password2.required">
              Las contraseñas no coinciden.
            </div>
          </b-form-group>

          <div>
            <div class="d-flex justify-content-center" :class="$v.form.password2.$invalid ? 'mb-2' : ''">
              <vue-recaptcha 
                @verify="onCaptchaVerified" 
                @expired="onCaptchaExpired" 
                @error="onCaptchaError" 
                :sitekey="$options.recaptchaSiteKey" 
                :loadRecaptchaScript="true"
                ref="recaptcha_reg"
              ></vue-recaptcha>
            </div>
            <div class="text-muted text-center" v-if="$v.form.captcha.$invalid">
              Debes completar el captcha. <span style="text-decoration: underline;cursor:pointer;" @click="onRecaptchaReset()">Resetear captcha</span>
            </div>
          </div>


          <div class="text-danger text-center py-2" v-if="submitError">
            Hubo un error con tu solicitud.
          </div>

          <div class="mt-4 d-flex">
            <b-button type="button" variant="info" class="mr-2" :disabled="loading" v-b-modal.login-modal 
              ><b-icon
                class="mr-2"
                icon="arrow-return-left"
                aria-hidden="true"
              />Regresar</b-button
            >
            <b-button type="submit" variant="success" :disabled="$v.form.$invalid || loading"
              ><b-icon
                class="mr-2"
                icon="person-plus"
                aria-hidden="true"
              />Registrarme</b-button
            >
          </div>
        </b-form>
      </template>

      <template #modal-footer="{close}">
        <div class="w-100">
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            @click="close()"
          >
            Close
          </b-button>
        </div>
      </template>
    </b-modal>
    <success-modal
      content="Gracias por registrarte, te llegará un correo con instrucciones para activar tu cuenta."
      @click="onSuccessModalClick()"
      id="registration_success_modal"
    ></success-modal>
  </div>

</template>

<script>
import { required, email, sameAs, and, minLength } from "vuelidate/lib/validators";
import axios from 'axios';
import Loader from './loaders/Loader';
import VueRecaptcha from 'vue-recaptcha';
import animalIcons from "../resources/animal_icon_list";
import SuccessModal from './modals/SuccessModal.vue';

const checkUsername = async (value, vm) => {
  // console.log(vm);
  // if (!vm.$v.form.username.email) {
  //   return false;
  // }
  vm.fetchingUser = false;
  let fd = new FormData();
  fd.append('username', value);
  try {
    const response = await axios.post('/registration/email', fd);
    // console.log(response);
    vm.fetchingUser = false;
    return response.data.unique;
  } catch(error) {
    console.log(error);
    return false;
  }
}

const passwordRegex = (value) => {
  let regex = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/);
  return regex.test(value);
}

export default {
  name: "RegistrationModal",
  user: window.configs.user,
  recaptchaSiteKey: window.configs.recaptchaSiteKey,
  components: {Loader, VueRecaptcha, SuccessModal },
  animalIcons: animalIcons,
  data() {
    return {
      form: {
        username: "",
        csrfToken: new String(this.$options.user.registerCsrf),
        name: "",
        lastname: "",
        password: "",
        password2: "",
        captcha: ""
      },
      loading: false,
      submitError: false,
      fetchingUser: false
    };
  },
  validations: {
    form: {
      username: {
        required,
        email,
        unique: and(required, email, checkUsername)
      },
      name: {
        required,
      },
      lastname: {
        required,
      },
      password: {
        required,
        minLengthValue: minLength(6),
        regex: passwordRegex
      },
      password2: {
        required,
        sameAs: and(required, sameAs('password'))
      },
      captcha: {
        required
      }
    },
  },
  methods: {
    onSubmit(e) {
      this.submitError = false;
      this.loading = true;
      // console.log(e);
      let fd = new FormData();
      fd.append('email', this.form.username);
      fd.append('password', this.form.password);
      fd.append('name', this.form.name);
      fd.append('lastname', this.form.lastname);
      fd.append('avatar_filename', this.getAvatar());
      fd.append('_captcha', this.form.captcha);
      fd.append('_token', this.form.csrfToken);
      axios.post('/registration', fd)
        .then(response => {
          // console.log(response);
          this.$bvModal.show('registration_success_modal');
          this.submitError = false;
          this.form = {
            ...this.form,
            username: "",
            name: "",
            lastname: "",
            password: "",
            password2: "",
            captcha: ""
          };
          this.loading = false;
        })
        .catch(error => {
          console.log(error);
          this.submitError = true;
          this.loading = false;
        });
    },
    onSuccessModalClick() {
      this.$bvModal.hide('registration_success_modal');
      this.$bvModal.hide('registration-modal');
    },
    getAvatar() {
      let randomIndex = Math.floor(
        Math.random() * this.$options.animalIcons.length
      );
      return this.$options.animalIcons[randomIndex];
    },
    onCaptchaVerified(r) {
      // console.log(r);
      this.form.captcha = r;
    },
    onCaptchaError(e) {
      console.log(e);
      this.form.captcha = "";
    },
    onCaptchaExpired() {
      this.form.captcha = "";
    },
    onRecaptchaReset() {
      this.$refs['recaptcha_reg'].reset();
      this.form.captcha = "";
    }
  },
};
</script>
