var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "menu-container position-fixed d-flex" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "b-toggle",
                rawName: "v-b-toggle.sidebar-menu",
                modifiers: { "sidebar-menu": true }
              }
            ],
            staticClass: "menu-toggle hover-scale-1"
          },
          [
            _c("div", {
              staticClass: "icon bg-img",
              style: "background-image:url('" + _vm.assets.icon + "')"
            }),
            _vm._v(" "),
            _c("div", { staticClass: "text-container" }, [_vm._v("Menú")])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "b-toggle",
                rawName: "v-b-toggle",
                value: _vm.$options.user.isLogged ? "sidebar-user-menu" : "",
                expression: "$options.user.isLogged ? 'sidebar-user-menu' : ''"
              },
              {
                name: "b-modal",
                rawName: "v-b-modal",
                value: _vm.$options.user.isLogged ? "" : "login-modal",
                expression: "$options.user.isLogged ? '' : 'login-modal'"
              }
            ],
            staticClass: "menu-toggle hover-scale-1"
          },
          [
            _c("div", { staticClass: "avatar-container icon" }, [
              _c(
                "div",
                {
                  staticClass: "avatar",
                  class: _vm.$options.user.isLogged ? "" : "grayscale"
                },
                [
                  _c("div", {
                    staticClass: "bg-img w-100 h-100",
                    style:
                      "background-image:url('icon_collection/animals/" +
                      _vm.avatarFile +
                      "')"
                  })
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "text-container" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$options.user.isLogged
                      ? _vm.$options.user.name
                      : "Ingresar"
                  ) +
                  "\n      "
              )
            ])
          ]
        ),
        _vm._v(" "),
        _vm.$options.user.roles &&
        (_vm.$options.user.roles.includes("ROLE_ADMIN") ||
          _vm.$options.user.roles.includes("ROLE_MANAGER"))
          ? _c(
              "div",
              {
                staticClass: "menu-toggle hover-scale-1",
                on: {
                  click: function($event) {
                    return _vm.goToAdmin()
                  }
                }
              },
              [
                _c("div", {
                  staticClass: "icon bg-img",
                  style: "background-image:url('" + _vm.assets.adminIcon + "')"
                }),
                _vm._v(" "),
                _c("div", { staticClass: "text-container" }, [_vm._v("Gestor")])
              ]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "b-sidebar",
        {
          attrs: {
            title: "Menú",
            id: "sidebar-menu",
            "backdrop-variant": "dark",
            "bg-variant": "light",
            backdrop: "",
            shadow: "",
            right: "",
            width: "50rem"
          }
        },
        [
          _c("div", { staticClass: "p-3 menu-sidebar " }, [
            _c(
              "nav",
              { staticClass: "mb-3" },
              [
                _c(
                  "b-nav",
                  { attrs: { vertical: "" } },
                  [
                    _c(
                      "b-nav-item",
                      {
                        style: "color:" + _vm.$options.configs.menuColor,
                        attrs: {
                          to: { name: "home" },
                          "exact-active-class": "active"
                        }
                      },
                      [_vm._v("Inicio")]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-nav-item",
                      {
                        style: "color:" + _vm.$options.configs.menuColor,
                        attrs: {
                          to: { name: "bases" },
                          "exact-active-class": "active"
                        }
                      },
                      [_vm._v("Bases del Concurso")]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-nav-item",
                      {
                        style: "color:" + _vm.$options.configs.menuColor,
                        attrs: {
                          to: { name: "jury" },
                          "exact-active-class": "active"
                        }
                      },
                      [_vm._v("Los Jurados")]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-nav-item",
                      {
                        style: "color:" + _vm.$options.configs.menuColor,
                        attrs: {
                          to: { name: "drawings" },
                          "exact-active-class": "active"
                        }
                      },
                      [_vm._v("Dibujos")]
                    ),
                    _vm._v(" "),
                    _vm.$options.user.isLogged
                      ? _c(
                          "b-nav-item",
                          {
                            style: "color:" + _vm.$options.configs.menuColor,
                            attrs: {
                              to: { name: "my_drawings" },
                              "exact-active-class": "active"
                            }
                          },
                          [_vm._v("Mis Dibujos")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.$options.user.isLogged &&
                    _vm.$options.user.contestAvailable
                      ? _c(
                          "b-nav-item",
                          {
                            style: "color:" + _vm.$options.configs.menuColor,
                            attrs: {
                              to: { name: "uploadDrawing" },
                              "exact-active-class": "active"
                            }
                          },
                          [_vm._v("Sube tu Dibujo")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.$options.user.isLogged
                      ? _c(
                          "b-nav-item",
                          {
                            directives: [
                              {
                                name: "b-modal",
                                rawName: "v-b-modal.login-modal",
                                modifiers: { "login-modal": true }
                              }
                            ],
                            style: "color:" + _vm.$options.configs.menuColor,
                            attrs: { "exact-active-class": "active" }
                          },
                          [_vm._v("Ingresar")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ])
        ]
      ),
      _vm._v(" "),
      _vm.$options.user.isLogged
        ? _c(
            "b-sidebar",
            {
              ref: "sidebar",
              attrs: {
                title: "Mi Cuenta",
                id: "sidebar-user-menu",
                "backdrop-variant": "dark",
                "bg-variant": "light",
                backdrop: "",
                shadow: "",
                right: "",
                width: "30rem"
              }
            },
            [
              _c("div", { staticClass: "p-3 menu-user-sidebar" }, [
                _c("div", { staticClass: "sidebar-avatar-container" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "b-modal",
                          rawName: "v-b-modal",
                          value: "avatar-modal",
                          expression: "'avatar-modal'"
                        }
                      ],
                      staticClass: "avatar-ring"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "switch-icon-container" },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "sync-alt" }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "avatar-container sidebar" }, [
                        _c("div", { staticClass: "avatar" }, [
                          _c("div", {
                            staticClass: "bg-img w-100 h-100",
                            style:
                              "background-image:url('icon_collection/animals/" +
                              _vm.avatarFile +
                              "')"
                          })
                        ])
                      ])
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "user-data name-container" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$options.user.name) +
                      " " +
                      _vm._s(_vm.$options.user.lastname) +
                      "\n      "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "user-data email-container" }, [
                  _vm._v(_vm._s(_vm.$options.user.email))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "actions-container" }, [
                  _c(
                    "div",
                    { staticClass: "action-container" },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: { name: "my_drawings" },
                            "exact-active-class": "active"
                          }
                        },
                        [_vm._v("Mis Dibujos")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "action-container" },
                    [
                      _vm.$options.user.contestAvailable
                        ? _c(
                            "router-link",
                            {
                              attrs: {
                                to: { name: "uploadDrawing" },
                                "exact-active-class": "active"
                              }
                            },
                            [_vm._v("Sube tu Dibujo")]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "action-container" }, [
                    _c("a", { attrs: { href: "/logout" } }, [
                      _vm._v("Cerrar Sesión")
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("avatar-modal")
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }