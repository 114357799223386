const animalIcon = [
  "001-elephant.svg",
  "002-snake.svg",
  "003-hippo.svg",
  "004-turtle.svg",
  "005-parrot.svg",
  "006-panda.svg",
  "007-boar.svg",
  "008-giraffe.svg",
  "009-squid.svg",
  "010-rhino.svg",
  "011-deer.svg",
  "012-stingray.svg",
  "013-beetle.svg",
  "014-shark.svg",
  "015-walrus.svg",
  "016-bat.svg",
  "017-hedgehog.svg",
  "018-spider.svg",
  "019-ladybug.svg",
  "020-dog.svg",
  "021-sloth.svg",
  "022-rabbit.svg",
  "023-crab.svg",
  "024-camel.svg",
  "025-zebra.svg",
  "026-beaver.svg",
  "027-horse.svg",
  "028-chicken.svg",
  "029-fox.svg",
  "030-frog.svg",
  "031-platypus.svg",
  "032-whale.svg",
  "033-cow.svg",
  "034-bullfinch.svg",
  "035-butterfly.svg",
  "036-clown fish.svg",
  "037-penguin.svg",
  "038-owl.svg",
  "039-crocodile.svg",
  "040-lama.svg",
  "041-bee.svg",
  "042-chameleon.svg",
  "043-buffalo.svg",
  "044-pig.svg",
  "045-cat.svg",
  "046-sheep.svg",
  "047-flamingo.svg",
  "048-lion.svg",
  "049-gorilla.svg",
  "050-mouse.svg",
];
export default animalIcon;
