<template>
  <b-modal
    :id="id"
    size="sm"
    centered
    header-class="custom-modal-header"
    ok-title="Cerrar"
  >
    <template #modal-header="{ close }">
      <h1>{{ headerText }}</h1>
      <b-button-close @click="close()"></b-button-close>
    </template>
    <div class="custom-modal-content">
      <div
        class="d-flex justify-content-center align-items-center pb-5"
        style="font-size:5rem;color:rgb(4,155,4);"
      >
        <div><font-awesome-icon icon="check-circle"></font-awesome-icon></div>
      </div>
      <p class="text-center">{{ content }}</p>
    </div>
    <template #modal-footer="{  }">
      <b-button size="sm" variant="success" @click="$emit('click')"
        >Cerrar</b-button
      >
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "SuccessModal",
  props: {
    headerText: {
      type: String,
      default: "¡Éxito!",
    },
    content: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "success-modal",
    },
  },
  data() { return {}},
  methods: {},
};
</script>
