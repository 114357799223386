<style lang="scss" scoped></style>

<template>
  <b-input-group size="lg" class="mb-2 flex-nowrap">
    <b-input-group-text slot="prepend" style="background-color:transparent"
      >Concursos</b-input-group-text
    >
    <b-form-select v-model="selectedId" :options="contests"></b-form-select>
  </b-input-group>
</template>

<script>
import axios from "axios";

export default {
  name: "ContestFilterInput",
  props: [],
  data() {
    return {
      contests: [],
      selectedId: null,
    };
  },
  mounted () {
    this.getContests();
  },
  methods: {
    getContests(onFail = () => {}) {
      axios
        .get(
          `/api/contests`
        )
        .then((response) => {
          // console.log(response);
          this.contests = [{value: null, text: "Todos"}, ...response.data.data.map((item)=> {return {value: item.id, text: item.name}})];
        })
        .catch((error) => {
          // handle error
          console.log(error);
          onFail(error);
        })
        .then(() => {
          // always executed
        });
    },
  },
  watch: {
    selectedId: function(newVal, oldVal) {
      this.$emit("change", newVal);
    },
  },
};
</script>

<style></style>
