var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-modal", {
    attrs: {
      id: "login-modal",
      title: "Ingresar",
      "header-bg-variant": "info",
      "header-text-variant": "light",
      "header-class": "align-items-baseline position-relative",
      "hide-footer": "",
      centered: "",
      "no-stacking": ""
    },
    scopedSlots: _vm._u([
      {
        key: "modal-header",
        fn: function(ref) {
          var close = ref.close
          return [
            _c("b-icon", {
              staticClass: "mr-2",
              attrs: { icon: "key", "aria-hidden": "true" }
            }),
            _vm._v(" "),
            _c("h5", [_vm._v("Ingresar")]),
            _vm._v(" "),
            _c("b-button-close", {
              on: {
                click: function($event) {
                  return close()
                }
              }
            })
          ]
        }
      },
      {
        key: "default",
        fn: function() {
          return [
            _c("loader", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.loading,
                  expression: "loading"
                }
              ]
            }),
            _vm._v(" "),
            _c("p", { staticClass: "mb-2" }, [
              _vm._v("¡Ingresa para participar o votar!")
            ]),
            _vm._v(" "),
            _c(
              "b-form",
              {
                attrs: { action: "/login" },
                on: {
                  submit: function($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.onSubmit($event)
                  }
                }
              },
              [
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      "label-size": "lg",
                      label: "Correo",
                      "label-for": "login-email"
                    }
                  },
                  [
                    _c(
                      "b-input-group",
                      { attrs: { size: "lg" } },
                      [
                        _c(
                          "b-input-group-prepend",
                          [
                            _c(
                              "b-input-group-text",
                              [_c("b-icon", { attrs: { icon: "person" } })],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("b-form-input", {
                          attrs: {
                            id: "login-email",
                            state: _vm.$v.form.username.$invalid ? false : null,
                            autocomplete: "username"
                          },
                          model: {
                            value: _vm.form.username,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "username", $$v)
                            },
                            expression: "form.username"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.$v.form.username.$invalid
                      ? _c("div", { staticClass: "text-muted text-right" }, [
                          _vm._v(
                            "\n          Debe ser un correo válido.\n        "
                          )
                        ])
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      "label-size": "lg",
                      label: "Contraseña",
                      "label-for": "login-password"
                    }
                  },
                  [
                    _c(
                      "b-input-group",
                      { attrs: { size: "lg" } },
                      [
                        _c(
                          "b-input-group-prepend",
                          [
                            _c(
                              "b-input-group-text",
                              [_c("b-icon", { attrs: { icon: "key" } })],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("b-form-input", {
                          attrs: {
                            id: "login-password",
                            type: "password",
                            state: _vm.$v.form.password.$invalid ? false : null,
                            autocomplete: "current-password"
                          },
                          model: {
                            value: _vm.form.password,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "password", $$v)
                            },
                            expression: "form.password"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.$v.form.password.$invalid
                      ? _c("div", { staticClass: "text-muted text-right" }, [
                          _vm._v("\n          Requerida\n        ")
                        ])
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.emailVerificationSent
                  ? _c(
                      "div",
                      { staticClass: "text-success text-center py-2" },
                      [
                        _c("div", [
                          _vm._v(
                            "Hemos enviado un correo a " +
                              _vm._s(_vm.emailVerification)
                          )
                        ])
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.submitError
                  ? _c("div", { staticClass: "text-danger text-center py-2" }, [
                      _vm.errorMsg === "_disabled"
                        ? _c("div", [
                            _vm._v(
                              "\n          Tu usuario ha sido deshabilitado.\n        "
                            )
                          ])
                        : _vm.errorMsg === "_email_verification"
                        ? _c("div", [
                            _vm._v("\n          No has verificado tu correo."),
                            _c("br"),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "text-muted hover-underline cursor-pointer",
                                staticStyle: { "font-size": "80%" },
                                on: {
                                  click: function($event) {
                                    return _vm.resendEmailVerification()
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "Para volver a mandar el correo de verificación has click aqui"
                                )
                              ]
                            )
                          ])
                        : _vm.errorMsg ===
                          "Too many failed login attempts, please try again in 1 minute."
                        ? _c("div", [
                            _vm._v(
                              "\n          Demasiados intentos consecutivos, intenta nuévamente en 1 minuto.\n        "
                            )
                          ])
                        : _vm.errorCode === 401
                        ? _c("div", [
                            _vm._v("\n          Credenciales incorrectas."),
                            _c("br"),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "text-muted hover-underline cursor-pointer",
                                staticStyle: { "font-size": "80%" },
                                on: {
                                  click: function($event) {
                                    return _vm.resetPassword()
                                  }
                                }
                              },
                              [_vm._v("Olvidé mi contraseña")]
                            )
                          ])
                        : _c("div", [
                            _vm._v(
                              "\n          Hubo un error con tu solicitud.\n        "
                            )
                          ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "mt-4 d-flex" },
                  [
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-modal",
                            rawName: "v-b-modal.registration-modal",
                            modifiers: { "registration-modal": true }
                          }
                        ],
                        staticClass: "mr-2",
                        attrs: {
                          type: "button",
                          variant: "info",
                          disabled: _vm.loading
                        }
                      },
                      [
                        _c("b-icon", {
                          staticClass: "mr-2",
                          attrs: { icon: "person-plus", "aria-hidden": "true" }
                        }),
                        _vm._v("Registrarme")
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-button",
                      {
                        attrs: {
                          type: "submit",
                          variant: "success",
                          disabled: _vm.$v.form.$invalid || _vm.loading
                        }
                      },
                      [
                        _c("b-icon", {
                          staticClass: "mr-2",
                          attrs: { icon: "shield-check", "aria-hidden": "true" }
                        }),
                        _vm._v("Ingresar")
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        },
        proxy: true
      },
      {
        key: "modal-footer",
        fn: function(ref) {
          var close = ref.close
          return [
            _c(
              "div",
              { staticClass: "w-100" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "float-right",
                    attrs: { variant: "primary", size: "sm" },
                    on: {
                      click: function($event) {
                        return close()
                      }
                    }
                  },
                  [_vm._v("\n        Close\n      ")]
                )
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }