var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c("page-header", {
        attrs: {
          title: "Los Jurados",
          "header-class": "jurado",
          subtitle: "",
          "bg-url": _vm.assets.headerBgUrl,
          "bg-position": "left bottom",
          "bg-size": ""
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "content jurado" }, [
        _c(
          "div",
          { staticClass: "content-outer" },
          [
            _vm._l(_vm.$options.configs.jury, function(jury, index) {
              return _c("jury-container", {
                key: index,
                attrs: {
                  title: jury.title,
                  text: jury.text,
                  imgUrl: jury.bgImage,
                  titleColor: jury.titleColor,
                  imgRight: jury.imgRight === "1"
                }
              })
            }),
            _vm._v(" "),
            _c("div", { staticClass: "mx-md-5 px-md-5" }, [
              _c(
                "div",
                { staticClass: "content-actions d-flex px-md-5 mx-md-5" },
                [
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-modal",
                          rawName: "v-b-modal.login-modal",
                          modifiers: { "login-modal": true }
                        }
                      ],
                      staticClass: "flex-grow-shrink-0 mr-2",
                      staticStyle: { "font-size": "100%" },
                      style:
                        "color:" +
                        _vm.$options.configs.buttonColor +
                        ";border-color:" +
                        _vm.$options.configs.buttonColor,
                      attrs: { squared: "", variant: "outline-success" }
                    },
                    [
                      _c("div", { staticClass: "d-flex flex-column" }, [
                        _c("div", { staticClass: "pt-2" }, [
                          _c("strong", [_vm._v("PARTICIPAR")])
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticStyle: { "font-size": "200%" } },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: "vote-yea" }
                            })
                          ],
                          1
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-modal",
                          rawName: "v-b-modal",
                          value:
                            _vm.$options.user && _vm.$options.user.isLogged
                              ? ""
                              : "login-modal",
                          expression:
                            "$options.user && $options.user.isLogged ? '' : 'login-modal'"
                        }
                      ],
                      staticClass: "flex-grow-shrink-0 mr-2",
                      staticStyle: { "font-size": "100%" },
                      style:
                        "color:" +
                        _vm.$options.configs.buttonColor +
                        ";border-color:" +
                        _vm.$options.configs.buttonColor,
                      attrs: {
                        type: "button",
                        to:
                          _vm.$options.user && _vm.$options.user.isLogged
                            ? { name: "drawings" }
                            : {},
                        squared: "",
                        variant: "outline-success"
                      }
                    },
                    [
                      _c("div", { staticClass: "d-flex flex-column" }, [
                        _c("div", { staticClass: "pt-2" }, [
                          _c("strong", [_vm._v("VOTAR")])
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticStyle: { "font-size": "200%" } },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: "person-booth" }
                            })
                          ],
                          1
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-toggle",
                          rawName: "v-b-toggle.sidebar-menu",
                          modifiers: { "sidebar-menu": true }
                        }
                      ],
                      staticClass: "flex-grow-shrink-0",
                      staticStyle: { "font-size": "100%" },
                      style:
                        "color:" +
                        _vm.$options.configs.buttonColor +
                        ";border-color:" +
                        _vm.$options.configs.buttonColor,
                      attrs: { squared: "", variant: "outline-success" }
                    },
                    [
                      _c("div", { staticClass: "d-flex flex-column" }, [
                        _c("div", { staticClass: "pt-2" }, [
                          _c("strong", [_vm._v("EXPLORA EL MENÚ")])
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticStyle: { "font-size": "200%" } },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: "compass" }
                            })
                          ],
                          1
                        )
                      ])
                    ]
                  )
                ],
                1
              )
            ])
          ],
          2
        )
      ]),
      _vm._v(" "),
      _c("page-footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }