<style lang="scss" scoped></style>

<template>
  <div>
    <b-modal
      id="reset-password-modal"
      title="Recuperar mi cuenta"
      header-bg-variant="info"
      header-text-variant="light"
      header-class="align-items-baseline position-relative"
      hide-footer
      centered
      no-stacking
    >
      <template #modal-header="{ close }">
        <b-icon icon="key" class="mr-2" aria-hidden="true" />
        <h5>Recuperar mi cuenta</h5>
        <b-button-close @click="close()" />
      </template>

      <template #default>
        <loader v-show="loading"></loader>
        <p class="mb-2">Aquí podrás cambiar tu contraseña.</p>
        <b-form action="/update-password" @submit.stop.prevent="onSubmit">

          <b-form-group
            label-size="md"
            label="Contraseña"
            label-for="login-password"
            :class="$v.form.password.$invalid ? 'mb-0' : ''"
          >
            <b-input-group size="md">
              <b-input-group-prepend>
                <b-input-group-text>
                  <b-icon icon="key" />
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input
                id="login-password"
                type="password"
                v-model="form.password"
                :state="$v.form.password.$invalid ? false : null"
                autocomplete="new-password"
              ></b-form-input>
            </b-input-group>
            <div class="text-muted text-right" v-if="!$v.form.password.required">
              Requerida
            </div>
            <div class="text-muted text-right" v-if="!$v.form.password.minLengthValue && $v.form.password.required">
              Debe contener al menos 6 caracteres.
            </div>
            <div class="text-muted text-right" v-if="!$v.form.password.regex && $v.form.password.required && $v.form.password.minLengthValue">
              Usa al menos una mayúscula, una minúscula y un número.
            </div>
          </b-form-group>
          <b-form-group
            label-size="md"
            label="Repetir Contraseña"
            label-for="login-password2"
            :class="$v.form.password2.$invalid ? 'mb-0' : ''"
          >
            <b-input-group size="md">
              <b-input-group-prepend>
                <b-input-group-text>
                  <b-icon icon="key" />
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input
                id="login-password2"
                type="password"
                v-model="form.password2"
                :state="$v.form.password2.$invalid ? false : null"
                autocomplete="new-password"
              ></b-form-input>
            </b-input-group>
            <div class="text-muted text-right" v-if="!$v.form.password2.required">
              Requerida
            </div>
            <div class="text-muted text-right" v-if="!$v.form.password2.sameAs && $v.form.password2.required">
              Las contraseñas no coinciden.
            </div>
          </b-form-group>

          <div>
            <div class="d-flex justify-content-center" :class="$v.form.password2.$invalid ? 'mb-2' : ''">
              <vue-recaptcha 
                @verify="onCaptchaVerified" 
                @expired="onCaptchaExpired" 
                @error="onCaptchaError" 
                :sitekey="$options.recaptchaSiteKey" 
                :loadRecaptchaScript="true"
                ref="recaptcha_reg"
              ></vue-recaptcha>
            </div>
            <div class="text-muted text-center" v-if="$v.form.captcha.$invalid">
              Debes completar el captcha. <span style="text-decoration: underline;cursor:pointer;" @click="onRecaptchaReset()">Resetear captcha</span>
            </div>
          </div>


          <div class="text-danger text-center py-2" v-if="submitError">
            Hubo un error con tu solicitud.
          </div>

          <div class="mt-4 d-flex">
            <b-button type="submit" variant="success" :disabled="$v.form.$invalid || loading"
              ><b-icon
                class="mr-2"
                icon="person-plus"
                aria-hidden="true"
              />Confirmar</b-button
            >
          </div>
        </b-form>
      </template>

      <template #modal-footer="{close}">
        <div class="w-100">
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            @click="close()"
          >
            Close
          </b-button>
        </div>
      </template>
    </b-modal>
    <success-modal
      content="Has cambiado tu contraseña, intenta ingresar."
      @click="onSuccessModalClick()"
      id="password_reset_success_modal"
    ></success-modal>
  </div>

</template>

<script>
import { required, sameAs, and, minLength } from "vuelidate/lib/validators";
import axios from 'axios';
import Loader from './loaders/Loader';
import VueRecaptcha from 'vue-recaptcha';
import SuccessModal from './modals/SuccessModal.vue';

const passwordRegex = (value) => {
  let regex = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/);
  return regex.test(value);
}

export default {
  name: "ResetPasswordModal",
  recaptchaSiteKey: window.configs.recaptchaSiteKey,
  resetPasswordCsrf: window.configs.user.resetPasswordCsrf,
  components: {Loader, VueRecaptcha, SuccessModal },
  props: ['resetToken'],
  data() {
    return {
      form: {
        password: "",
        password2: "",
        captcha: ""
      },
      loading: false,
      submitError: false,
    };
  },
  validations: {
    form: {
      password: {
        required,
        minLengthValue: minLength(6),
        regex: passwordRegex
      },
      password2: {
        required,
        sameAs: and(required, sameAs('password'))
      },
      captcha: {
        required
      }
    },
  },
  methods: {
    onSubmit(e) {
      this.submitError = false;
      this.loading = true;
      // console.log(e);
      let fd = new FormData();
      fd.append('new_password', this.form.password);
      fd.append('password_reset_token', this.resetToken);
      fd.append('_captcha', this.form.captcha);
      fd.append('_token', this.$options.resetPasswordCsrf);
      axios.post('/update-password', fd)
        .then(response => {
          // console.log(response);
          this.$bvModal.show('password_reset_success_modal');
          this.submitError = false;
          this.form = {
            ...this.form,
            password: "",
            password2: "",
            captcha: "",
          };
          this.loading = false;
        })
        .catch(error => {
          console.log(error);
          this.submitError = true;
          this.loading = false;
        });
    },
    onSuccessModalClick() {
      this.$bvModal.hide('password_reset_success_modal');
      this.$bvModal.hide('reset-password-modal');
      this.$bvModal.show('login-modal');
    },
    onCaptchaVerified(r) {
      // console.log(r);
      this.form.captcha = r;
    },
    onCaptchaError(e) {
      console.log(e);
      this.form.captcha = "";
    },
    onCaptchaExpired() {
      this.form.captcha = "";
    },
    onRecaptchaReset() {
      this.$refs['recaptcha_reg'].reset();
      this.form.captcha = "";
    }
  },
};
</script>
