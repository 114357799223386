<style lang="scss" scoped>
.img {
  width: 100%;
  max-height: 95vh;
  object-fit: contain;
}
.drawing-description {
  padding: 0.5em 1em;
  text-align: center;
  @media only screen and (min-width: 1200px) {
    padding-right: 20%;
    padding-left: 20%;
  }
}
.drawing-info {
  padding: 0.5em 1em;
  text-align: right;
  font-size: 80%;
  @media only screen and (min-width: 1200px) {
    padding-right: 20%;
    padding-left: 20%;
  }
}
.spinner-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .spinner {
    width: 30%;
    height: 30%;
  }
}
.rating-container {
  padding: 0.5em 25%;
  position: relative;
  @media only screen and (max-width: 1200px) {
    padding: 0.5em ;
  }
}
.jury-vote-title {
  font-size: 150%;
  text-align: center;
  margin-bottom: 1em;
}
.jury-vote-container {
  .jury-vote {
    padding-bottom: 3em;
    .criteria-description{
      padding-bottom: 1em;
    }
    .value-table {
      font-size: 80%;
    }
  }

}
.content-inner {
  position: relative;
}
body {
  &.modal-open {
    @media screen and (min-width: 1200px) {
      .menu-container {
        padding-right: 0px;
      }
    }
  }
}
</style>
<template>
  <b-modal
    :id="id"
    size="xl"
    centered
    header-class="custom-modal-header postion-relative py-0"
    ok-title="Cerrar"
    body-class="p-0"
    footer-class="pt-0"
    footer-border-variant="white"
    content-class="overflow-hidden"
  >
    <template #modal-header="{ close }">
      <div class="position-absolute" style="right:0.5em;top:0.5em;z-index:1;">
        <b-button-close @click="close()"></b-button-close>
      </div>
    </template>
    <div class="content-inner" v-if="drawing.title">
      <div class="spinner-container" v-if="!loaded">
        <image-spinner-icon class="spinner"></image-spinner-icon>
      </div>
      <b-img class="img" fluid :src="drawing.img_path" />
      <div class="drawing-description">
        <h2>{{ drawing.title }}</h2>
        <p>{{ drawing.description }}</p>
      </div>
      <div class="drawing-info">
        <div class="info-group">
          <span class="title">Concurso: </span
          ><span class="data">{{ drawing.contest.name }}</span>
        </div>
        <div class="info-group">
          <span class="title">Categoría: </span
          ><span class="data"
            >{{ drawing.category.name }} ({{ drawing.category.min_age }}-{{
              drawing.category.max_age
            }}años)</span
          >
        </div>
      </div>
      <div
        class="rating-container"
        v-if="
          this.$options.user && this.$options.user.isLogged && this.$options.user.canPublicVote && !this.$options.user.canJuryVote && drawing.contest.publicVoteAllowed &&
            (!drawing.created_by_me ||
              $options.user.roles.includes('ROLE_ADMIN'))
        "
      >
        <loader v-if="loadingVote"></loader>
        <div class="text-center mt-3"><strong>Votar</strong></div>
        <rating-input
          class=""
          size="lg"
          @change="onPublicVoteChange"
          :initValue="drawing.public_vote"
        ></rating-input>
      </div>
      <div style="text-align:center"
        v-if=" this.$options.user && this.$options.user.isLogged && this.$options.user.canPublicVote && !this.$options.user.canJuryVote && !drawing.contest.publicVoteAllowed &&
            (!drawing.created_by_me ||
              $options.user.roles.includes('ROLE_ADMIN'))">
        <i>La votación pública para el concurso de este dibujo ha sido cerrada.</i>
      </div>
      <div
        class="rating-container"
        v-if="
          this.$options.user && this.$options.user.isLogged && this.$options.user.canJuryVote && drawing.contest.juryVoteAllowed &&
            (!drawing.created_by_me ||
              $options.user.roles.includes('ROLE_ADMIN'))
        "
      >
        <loader v-if="loadingVote"></loader>
        <div class="jury-vote-title"><strong>Voto de Jurado</strong></div>
        <div class="jury-vote-container">
          <div class="jury-vote" v-for="criteria in drawing.contest.juryCriteria" :key="criteria.id">
            <div class="criteria-name"><strong>{{criteria.name}}</strong></div>
            <div class="criteria-description"><i>{{criteria.description}}</i></div>
            <div class="value-table">
              <b-table :items="criteria.values" small :fields="[
                {
                  key: 'value',
                  label: 'Número de Estrellas'
                },
                {
                  key: 'description',
                  label: 'Criterios de Evaluación'
                }
              ]"></b-table>
            </div>
            <rating-input
              class=""
              size="lg"
              :showDisqualified="true"
              @change="onJuryVoteChange($event, criteria.id)"
              :initValue="findJuryVoteInitValue(criteria.id)"
            ></rating-input>
          </div>
        </div>
      </div>
      <div style="text-align:center"
        v-if=" this.$options.user && this.$options.user.isLogged && this.$options.user.canJuryVote && !drawing.contest.juryVoteAllowed &&
            (!drawing.created_by_me ||
              $options.user.roles.includes('ROLE_ADMIN'))">
        <i>La votación de jurado para el concurso de este dibujo ha sido cerrada.</i>
      </div>
      <div style="text-align:center"
        v-if=" this.$options.user && this.$options.user.isLogged && drawing.created_by_me">
        <i>No puedes votar por un dibujo que subiste.</i>
      </div>
    </div>
    <template #modal-footer="{ close }">
      <b-button size="sm" variant="success" @click="close">Cerrar</b-button>
    </template>
  </b-modal>
</template>

<script>
import axios from "axios";
import RatingInput from "../forms/RatingInput.vue";
import Loader from "../loaders/Loader.vue";

export default {
  name: "ImageModal",
  components: { RatingInput, Loader },
  user: window.configs.user,
  props: {
    drawing: {
      type: Object,
      default: {
        id: null,
        title: "Dibujo",
        img_path: "",
        description: "",
        public_vote: null,
        index: 0,
        created_by_me: false,
        contest: {
          name: "",
        },
        category: {
          name: "",
        },
      },
    },
    id: {
      type: String,
      default: "drawing-modal",
    },
  },
  data() {
    return {
      loaded: true,
      loadingVote: false,
    };
  },
  mounted() {},
  methods: {
    findJuryVoteInitValue(criteriaId) {
      let foundIndex = this.drawing.jury_votes.findIndex(v=>v.criteria.id === criteriaId)
      if (foundIndex === -1) {
        return null
      }
      return this.drawing.jury_votes[foundIndex].value
    },
    onPublicVoteChange(value) {
      this.publicVoteRequest(value);
    },
    onJuryVoteChange(value, criteraId) {
      // console.log("event", event)
      this.juryVoteRequest(value, criteraId);
    },
    juryVoteRequest(value, criteriaId) {
      this.loadingVote = true;
      let fd = new FormData();
      fd.append("participation_id", this.drawing.id);
      fd.append("type", "jury");
      fd.append("value", value);
      fd.append("criteria_id", criteriaId);
      axios
        .post(`/api/vote`, fd)
        .then((response) => {
          // console.log("this.drawing", this.drawing);
          let foundIndex = this.drawing.jury_votes.findIndex(v=>v.criteria.id === criteriaId)
          if (foundIndex === -1) {
            this.drawing.jury_votes.push(response.data);
          }
          else {
            this.drawing.jury_votes[foundIndex] = response.data;
          }
          
          this.$emit("juryVoteChange", { ...this.drawing });
        })
        .catch((error) => {
          // handle error
          console.log(error);
        })
        .then(() => {
          // always executed
          this.loadingVote = false;
        });
    },
    publicVoteRequest(value) {
      this.loadingVote = true;
      let fd = new FormData();
      fd.append("participation_id", this.drawing.id);
      fd.append("type", "public");
      fd.append("value", value);
      axios
        .post(`/api/vote`, fd)
        .then((response) => {
          this.drawing.public_vote = response.data.value;
          this.$emit("publicVoteChange", { ...this.drawing });
        })
        .catch((error) => {
          // handle error
          console.log(error);
        })
        .then(() => {
          // always executed
          this.loadingVote = false;
        });
    },
  },
};
</script>
