var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("menu-sidebar"),
      _vm._v(" "),
      _c("login-modal"),
      _vm._v(" "),
      _c("registration-modal"),
      _vm._v(" "),
      _c("router-view"),
      _vm._v(" "),
      _c("div", { staticClass: "bottom-menu-spacer" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }